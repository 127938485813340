/* global Vue, AZURE_URI, LOCALE, IS_BOOKING */

import BookingAccessCode from './BookingAccessCode'
import BookingCalendar from './BookingCalendar'
import BookingModal from './BookingModal'
import Guests from './Guests'
import { displayDate, displayGuests } from '../../utils-common'
import { AvailabilityCalendar } from '../../classes/AvailabilityCalendar'
import { setErrorAndStopLoading } from './Notifications'
import Modal from './Modal'

export default {
  name: 'BookingMenu',
  components: { BookingAccessCode, BookingCalendar, BookingModal, Guests, Modal },
  template: `
<div class="booking-menu" ref="containerRef">
  <div class="booking-menu__summary container">
    <span>
      {{ displayDate(bookingParams) }}
      <br>
      {{ displayGuests(bookingParams, $t, property?.maxChildAge === 0) }}
    </span>
    <button title="$t('bookingModal.updateBtn')" @click="openContainer()"><i class="icon-search"></i></button>
  </div>

  <div class="container">
    <div class="booking-menu__inputs">
      <div class="booking-menu__property" v-if="IS_BOOKING">
        <label for="property">
          {{ $t('bookingModal.property') }}
          <i :class="'icon-caret-' + (level === 'property' ? 'up' : 'down')"></i>
        </label>
        <input
          id="property"
          placeholder="Select property"
          type="text"
          readonly
          @click="toggleModal('property')"
          ref="propertyInput"
          :value="bookingParams.property.title">
      </div>

      <div class="booking-menu__dates">
        <label for="dates">
          {{ $t('bookingModal.date') }}
          <i :class="'icon-caret-' + (level === 'calendar' ? 'up' : 'down')"></i>
        </label>
        <input
          id="dates"
          placeholder="Select date range"
          type="text"
          readonly
          @click="toggleModal('calendar')"
          ref="datesInput"
          :value="displayDate(bookingParams)">
      </div>

      <div class="booking-menu__guests">
        <label for="guests">
          {{ $t('bookingModal.guests') }}
          <i :class="'icon-caret-' + (level === 'guests' ? 'up' : 'down')"></i>
        </label>
        <input
          id="guests"
          placeholder="Select guests"
          type="text"
          readonly
          @click="toggleModal('guests')"
          ref="guestsInput"
          :value="displayGuests(bookingParams, $t, property?.maxChildAge === 0)">
      </div>

      <div class="booking-menu__promo-code">
        <BookingAccessCode :bookingParams="bookingParams" :accessCodeInfo="accessCodeInfo" @onFocus="closeModal()" />
      </div>

      <div class="booking-menu__update">
        <button
          class="button button--primary"
          @click="checkAccessCodeAndUpdate()">
          {{ $t('bookingModal.updateBtn') }}
        </button>
      </div>
    </div>
  </div>
</div>

<BookingModal
  :isOpen="isModalOpen"
  :level="level"
  :containerOffset="modalOffset"
  :property="property"
  :propertyIds="propertyIds"
  :bookingParams="bookingParams"
  :bookingParamsChanged="bookingParamsChanged"
  :availabilityCalendar="availabilityCalendar"
  :accessCodeInfo="accessCodeInfo"
  @updateBookingParams="checkAccessCodeAndUpdate()"
  @changeProperty="changeProperty"
  @changeLevel="e => level = e"
  @closeModal="closeModal" />

  <Modal
    v-if="IS_BOOKING"
    :isOpen="firstAvailableDate"
    @closeModal="firstAvailableDate = null"
    :title="$t('bookingModal.datesUnavailable' + (bookingParams.accessCode ? 'ForAccessCode' : ''))"
    class="change-dates-modal"
    >
    <div class="modal__content large-text" v-html="$t('bookingModal.datesUnavailable'+(bookingParams.accessCode ? 'ForAccessCode' : '')+'Content', { property: property.title, dates: displayDate({ startDate: firstAvailableDate, nights: bookingParams.nights }) })"></div>
    <div class="modal__buttons">
      <button @click="firstAvailableDate = null" class="button button--outline">{{ $t('cancel') }}</button>
      <button @click="applyFirstAvailableDate" class="button">{{ $t('bookingModal.changeDates') }}</button>
    </div>
  </Modal>
  `,
  props: {
    startDate: Object,
    nights: Number,
    adults: Number,
    children: Array,
    property: Object,
    propertyIds: Array,
    accessCode: String,
  },
  emits: ['updateBookingParams', 'error'],
  setup (props, { emit }) {
    const bookingParams = Vue.reactive({
      property: props.property,
      startDate: props.startDate,
      nights: props.nights,
      adults: props.adults,
      children: props.children,
      accessCode: props.accessCode,
    })
    const accessCodeInfo = Vue.reactive({
      code: bookingParams.accessCode,
      isValid: null,
      isRemoved: false,
    })
    const { propertyIds } = Vue.toRefs(props)
    const unitId = Vue.ref(null)
    const availabilityCalendar = Vue.reactive(new AvailabilityCalendar())
    const isModalOpen = Vue.ref(false)
    const level = Vue.ref('inputs')
    const bookingParamsChanged = Vue.ref(false)
    const firstAvailableDate = Vue.ref(null)

    const propertyInput = Vue.ref(null)
    const datesInput = Vue.ref(null)
    const guestsInput = Vue.ref(null)

    const containerRef = Vue.ref(null)

    const modalOffset = Vue.ref(null)

    Vue.watch([propertyIds, unitId], async ([propertyIds, unitId]) => {
      try {
        await availabilityCalendar.loadAvailability({ propertyIds, unitId, accessCode: bookingParams.accessCode })
        checkDateAvailability()
      } catch (e) {
        setErrorAndStopLoading(e)
      }
    })

    Vue.watch(bookingParams, () => {
      bookingParamsChanged.value = true
    })

    async function checkAccessCodeAndUpdate () {
      accessCodeInfo.code = bookingParams.accessCode
      accessCodeInfo.isRemoved = false
      accessCodeInfo.isValid = await checkAccessCode()

      if (accessCodeInfo.isValid) {
        bookingParamsChanged.value = false
        emit('updateBookingParams', bookingParams)
        level.value = 'inputs'
        closeModal()
      }
    }

    async function checkAccessCode () {
      bookingParams.accessCode = bookingParams.accessCode.trim()
      try {
        if (bookingParams.accessCode !== '') {
          const response = await fetch(`${AZURE_URI}CheckAccessCode?accessCode=${encodeURIComponent(bookingParams.accessCode)}&locale=${LOCALE}`)
          if (!response.ok) {
            throw new Error('Error checking access code.')
          }
          const ids = await response.json()
          return (IS_BOOKING ? ids.filter(id => id === (bookingParams.property?.phobsId ?? propertyIds.value[0])) : ids).length > 0
        }
      } catch (err) {
        setErrorAndStopLoading(err)
      }

      return true
    }

    function changeProperty (e) {
      bookingParams.accessCode = ''
      bookingParams.property = e
      availabilityCalendar.loadAvailability({ propertyIds: [e.phobsId] })

      Vue.nextTick(() => handleResize())
    }

    function openContainer () {
      isModalOpen.value = true
    }

    function toggleModal (l, options) {
      if (isModalOpen.value && level.value === l) {
        isModalOpen.value = false
        level.value = null
        return
      }

      unitId.value = options?.unitId ? options.unitId : null
      isModalOpen.value = true
      level.value = l
      handleResize()
    }

    function closeModal () {
      isModalOpen.value = false
    }

    function checkDateAvailability () {
      if (availabilityCalendar.isDateRangeAvailable(bookingParams.startDate, bookingParams.nights)) return
      firstAvailableDate.value = availabilityCalendar.getFirstAvailableDateRange(bookingParams.nights)
    }

    function applyFirstAvailableDate () {
      bookingParams.startDate = firstAvailableDate.value
      firstAvailableDate.value = null
      checkAccessCodeAndUpdate()
    }

    function getModalOffset () {
      switch (level.value) {
        case 'property':
          return propertyInput.value.getBoundingClientRect()?.x
        case 'calendar':
          return datesInput.value.getBoundingClientRect()?.x
        case 'guests':
          return guestsInput.value.getBoundingClientRect()?.x
      }
      return 0
    }

    function handleResize () {
      if (isModalOpen.value) {
        modalOffset.value = getModalOffset()
      }

      if (window.matchMedia('(min-width: 768px)').matches && level.value === 'inputs') {
        closeModal()
      }
    }

    function handleScroll () {
      if (containerRef.value.getBoundingClientRect().top === 0) {
        containerRef.value.classList.add('booking-menu--sticky')
      } else {
        containerRef.value.classList.remove('booking-menu--sticky')
      }
    }

    Vue.onMounted(async () => {
      if (!(await checkAccessCode())) {
        bookingParams.accessCode = ''
        accessCodeInfo.isRemoved = true
      } else if (bookingParams.accessCode !== '') {
        accessCodeInfo.code = bookingParams.accessCode
        accessCodeInfo.isValid = true
      }
      window.addEventListener('resize', handleResize)
      window.addEventListener('scroll', handleScroll)

      handleResize()
      handleScroll()
    })

    Vue.onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      bookingParams,
      availabilityCalendar,
      propertyInput,
      datesInput,
      guestsInput,
      isModalOpen,
      containerRef,
      level,
      modalOffset,
      displayDate,
      displayGuests,
      openContainer,
      toggleModal,
      closeModal,
      accessCodeInfo,
      bookingParamsChanged,
      checkAccessCodeAndUpdate,
      changeProperty,
      firstAvailableDate,
      applyFirstAvailableDate,
      IS_BOOKING,
    }
  },
}
