/* global Vue, AZURE_URI, LOCALE */

const ListProperties = {
  name: 'ListProperties',
  props: {
    hotelsAndResorts: { type: Array },
    campsites: { type: Array },
  },
  emits: ['focusImage', 'updateProperty'],
  template: `
    <div v-if="hotelsAndResorts.length">
      <div class="title">{{ $t('bookingModal.hotelsResorts') }}</div>
      <ul class="no-bullets">
        <li v-for="property in hotelsAndResorts" :key="property.id">
          <button @click="$emit('updateProperty', property)" @mouseover="$emit('focusImage', property.image)">
            <span>{{ property.title }}</span>
            <span class="stars stars--small" :class="'theme--' + property.brand">
              <i v-for="n in property.stars" class="icon-star"></i>
            </span>
          </button>
        </li>
      </ul>
    </div>
    <div v-if="campsites.length">
      <div class="title">{{ $t('bookingModal.campsites') }}</div>
        <ul class="no-bullets">
          <li v-for="property in campsites" :key="property.id">
            <button @click="$emit('updateProperty', property)" @mouseover="$emit('focusImage', property.image)">
              <span>{{ property.title }}</span>
              <span class="stars stars--small" :class="'theme--' + property.brand">
                <i v-for="n in property.stars" class="icon-star"></i>
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  `,
}

export default {
  name: 'PropertySelector',
  components: { ListProperties },
  template: `
  <div class="property-selector">
    <div class="property-selector__destination" ref="destinationsRef">
      <ul class="no-bullets">
        <li v-for="destination in destinations" :key="destination">
          <button @click="changeDestination(destination)" :class="{ active: selectedDestination === destination }">
            <span>{{ destination }}</span>
            <i :class="'icon-caret-' + (selectedDestination === destination ? 'up' : 'down')"></i>
          </button>
          <div v-if="selectedDestination === destination" class="properties" ref="destinationPropertiesRef">
            <ListProperties :hotelsAndResorts="hotelsAndResorts" :campsites="campsites" @focusImage="focusImage = $event" @updateProperty="$emit('updateProperty', $event)" />
          </div>
        </li>
      </ul>
    </div>
    <div class="property-selector__property properties">
      <ListProperties :hotelsAndResorts="hotelsAndResorts" :campsites="campsites" @focusImage="focusImage = $event" @updateProperty="$emit('updateProperty', $event)" />
    </div>
    <div class="property-selector__image" v-if="focusImage">
      <img :src="focusImage" />
    </div>
  </div>
  `,
  props: {
    property: Object,
  },
  emits: ['updateProperty'],
  setup (props, { emit }) {
    const destinationsRef = Vue.ref(null)
    const selectedDestination = Vue.ref(props.property?.destination)
    const destinations = ['rovinj', 'vrsar', 'zagreb', 'dubrovnik']
    const allProperties = Vue.ref([])
    const destinationProperties = Vue.computed(() => allProperties.value
      .filter(p => p.destination === selectedDestination.value)
      .sort((a, b) => (a.propertyListingWeight ?? Number.MAX_VALUE) - (b.propertyListingWeight ?? Number.MAX_VALUE)))

    const hotelsAndResorts = Vue.computed(() => destinationProperties.value.filter(p => p.menuGroup !== 'camping'))
    const campsites = Vue.computed(() => destinationProperties.value.filter(p => p.menuGroup === 'camping'))

    const focusImage = Vue.ref(props.property?.image)

    async function getProperties () {
      const response = await fetch(`${AZURE_URI}GetProperties?locale=${LOCALE}`, { priority: 'high' })
      allProperties.value = (await response.json()).filter(p => p.phobsId)
    }

    function changeDestination (destination) {
      if (!window.matchMedia('(min-width: 768px)').matches && selectedDestination.value === destination) {
        selectedDestination.value = null
        focusImage.value = null

        destinationsRef.value.scrollIntoView()
        return
      }

      selectedDestination.value = destination
      if (!window.matchMedia('(min-width: 768px)').matches) destinationsRef.value.scrollIntoView()

      if (destinationProperties.value[0]) {
        focusImage.value = destinationProperties.value[0].image
      }
    }

    getProperties()

    return {
      destinationsRef,
      destinations,
      selectedDestination,
      hotelsAndResorts,
      campsites,
      focusImage,
      changeDestination,
    }
  },
}
