/* global Vue, VeeValidate, PREFIX, WWW_HOME_URL, DATA_LAYER */

import { AppError, ErrorLevel } from '../../classes/Errors'
import { useCart, setClient } from '../../use-cart'
import LoyaltyInvite from '../../loyalty/components/LoyaltyInvite'
import CountriesDropdown from '../components/CountriesDropdown'
import PhoneNumber from '../components/PhoneNumber'
import { AuthService } from '../../AuthService'
import { setErrorAndStopLoading } from '../components/Notifications'
import ReservationSummary from '../components/ReservationSummary'

export default {
  name: 'Step3',
  components: { LoyaltyInvite, CountriesDropdown, PhoneNumber, ReservationSummary },
  template: `
<div>
  <div class="card-border personal-details">
    <div class="card-border__wrapper">
      <div class="card-border__content">
        <h3 class="no-margin-top">{{ $t('stepThree.reservationHolder') }}</h3>
        <hr class="card-border__hr">
        <div v-if="localClient" class="grid" ref="formElement">
          <fieldset class="col-12">
            <legend>{{ $t('stepThree.title') }}</legend>

            <div class="personal-details__titles">
              <div class="personal-details__item">
                <input type="radio" id="mr" name="title" v-model="localClient.title" value="Mr">
                <label for="mr">{{ $t('stepThree.mr') }}</label>
              </div>

              <div class="personal-details__item">
                <input type="radio" id="mrs" name="title" v-model="localClient.title" value="Mrs">
                <label for="mrs">{{ $t('stepThree.mrs') }}</label>
              </div>
            </div>
            <span class="field-error">{{ formErrors.title }}</span>
          </fieldset>

          <label for="name" class="col-12 col-lg-6 form-element no-margin">
            {{ $t('stepThree.name') }}
            <input id="name" type="text" v-model="localClient.firstName">
            <span class="field-error">{{ formErrors.firstName }}</span>
          </label>

          <label for="lastName" class="col-12 col-lg-6 form-element no-margin">
            {{ $t('stepThree.lastName') }}
            <input id="lastName" type="text" v-model="localClient.lastName">
            <span class="field-error">{{ formErrors.lastName }}</span>
          </label>

          <label for="email" class="col-12 col-lg-6 form-element no-margin">
            {{ $t('stepThree.email') }}
            <input id="email" type="email" v-model="localClient.email" ref="emailField" :readonly="isAuthenticated && !isMember">
            <span class="field-error">{{ formErrors.email }}</span>
          </label>

          <label for="verifyEmail" class="col-12 col-lg-6 form-element no-margin">
            {{ $t('stepThree.verifyEmail') }}
            <input id="verifyEmail" type="email" v-model="localClient.verifyEmail" :readonly="isAuthenticated && !isMember">
            <span class="field-error">{{ formErrors.verifyEmail }}</span>
          </label>

          <div class="col-12 col-lg-6 label form-element no-margin">
            <CountriesDropdown :countryCode="localClient.country" id="countryDropdown" @countryChanged="Object.assign(localCountry, $event); countryPrefix = $event.phone_code" :label="$t('stepThree.country')" />
            <input id="country" type="hidden" v-model="localClient.country">
            <span class="field-error">{{ formErrors.country }}</span>
          </div>

          <div class="col-12 col-lg-6 label form-element no-margin">
            <PhoneNumber :countryPrefix="countryPrefix" :phoneNumber="localClient.mobile" id="phoneNumber" @phoneNumberChanged="localClient.mobile = $event" :label="$t('stepThree.phoneNumber')" />
            <input id="mobile" type="hidden" v-model="localClient.mobile">
            <span class="field-error">{{ formErrors.mobile }}</span>
          </div>

          <label for="dob" v-if="displayBirthdate" class="col-12 col-lg-6 form-element no-margin">
            {{ $t('stepThree.birthdate') }}
            <input id="dob" type="date" v-model="localClient.birthdate" :max="new Date().toISOString().substring(0,10)">
            <span class="field-error">{{ formErrors.birthdate }}</span>
          </label>

          <label  v-if="displayRegistrationPlate" for="registrationPlate" class="col-12 col-lg-6 form-element no-margin">
            {{ $t('stepThree.vehiclePlate') }}
            <input id="registrationPlate" type="text" v-model="localClient.registrationPlate">
          </label>

          <div class="col-12">
            <label for="comments">{{ $t('stepThree.commentsRequests') }}</label>
            <textarea id="comments" v-model="localClient.comments"></textarea>
          </div>

          <div class="col-12" v-show="!cart.canUseMemberRate.value">
            <label for="newsletter" class="label-checkbox">
              <input id="newsletter" type="checkbox" :checked="newsletter" @change="handleCheckboxChange">
              {{ $t('stepThree.subscribeNewsletter') }}
            </label>
          </div>

          <div class="col-12">
            <label for="sendSMS" class="label-checkbox">
              <input id="sendSMS" type="checkbox" :checked="sms" @change="handleCheckboxChange">
              {{ $t('stepThree.confirmationSms') }}
            </label>
          </div>

          <div class="col-12">
            <ReservationSummary
              v-show="isMobile"
              :stepNumber="3"
              :property="property"
              :priceQuote="priceQuote"
              :propertyAvailability="propertyAvailability"
              :isOpen="true"
              :isInline="true"
              :canRemoveUnit="false"
              guestSummarySource="cart">
            </ReservationSummary>
          </div>

          <div class="col-12" v-if="isMobile">
            <button class="button button--block" @click="$emit('closeSummary'); onSubmitClient()">{{ $t('stepThree.continue') }}</button>
          </div>

          <p class="col-12 personal-details__note">
            <i18n-t tag="span" keypath="stepThree.personalDetailsNote" scope="global">
              <template #privacyPolicy>
                <a :href="WWW_HOME_URL + PREFIX + '/privacy-policy'" target="_blank">{{ $t('stepThree.privacyPolicy') }}</a>
              </template>
            </i18n-t>
          </p>

          <p class="col-12 no-margin italic">{{ $t('formValidation.required') }}</p>

          <p class="col-12 debug">
            <button @click="setErrorAndStopLoading('test errror')">Test error</button>
          </p>
          <teleport to="#continue-button">
            <button @click="$emit('closeSummary'); onSubmitClient()">{{ $t('stepThree.continue') }}</button>
          </teleport>
        </div>
      </div>
    </div>
  </div>
</div>`,
  emits: [ 'closeSummary' ],
  props: { isMobile: Boolean, property: Object, priceQuote: Object, propertyAvailability: Object },
  setup(props) {
    const { cart } = useCart()
    const { t } = VueI18n.useI18n()
    const router = VueRouter.useRouter()
    const localClient = Vue.reactive({})
    const formErrors = Vue.reactive({})
    const countryPrefix = Vue.ref('')
    const isMember = Vue.ref(false)
    const isAuthenticated = Vue.ref(false)
    const localCountry = Vue.reactive({})
    const emailField = Vue.ref(null)
    const formElement = Vue.ref(null)

    const displayBirthdate = cart.becomeMember.value && !isMember.value && !cart.accessCode.value

    const nativeEmailCheck = (value) => {
      if (!value || !value.length) {
        return t('formValidation.required')
      }
      if (!emailField.value.validity.valid) {
        return t('formValidation.email')
      }
      return true
    }

    const verifyEmailCheck = (value) => {
      if (!value || !value.length) {
        return t('formValidation.required')
      }
      if (value !== localClient.email) {
        return t('formValidation.notVerifiedMail')
      }
      return true
    }

    const validationSchema = {
      title: 'required',
      firstName: 'required',
      lastName: 'required',
      email: nativeEmailCheck,
      verifyEmail: verifyEmailCheck,
      country: 'required',
      mobile: 'required|phone'
    }

    if (displayBirthdate) {
      validationSchema.birthdate = 'required|birthdate'
    }
    const displayRegistrationPlate = cart.includesPitches.value

    const { handleSubmit, errors, isSubmitting } = VeeValidate.useForm({
      validationSchema,
      initialValues: localClient,
    })

    Object.keys(validationSchema).forEach((filed) => {
      const { value: fieldValue, errorMessage: fieldError } = VeeValidate.useField(filed)
      localClient[filed] = fieldValue
      formErrors[filed] = fieldError
    })

    Vue.watch(isSubmitting, () => {
      if (Object.keys(errors).length > 0 && formElement.value) {
        const highlight = Array.from(formElement.value.querySelectorAll('.field-error')).filter(er => er.innerHTML)[0] || null
        if (highlight) { highlight.parentNode.scrollIntoView({ behavior: 'smooth' }) }
      }
    })

    const onSubmitClient = handleSubmit(() => {
      try {
        setClient(localClient)
        if (cart.canGo2Step4) {
          router.push('/step4')
        } else {
          throw new Error("can't go to step 4")
        }
      } catch (e) {
        setErrorAndStopLoading(e)
      }
    })

    const newsletter = Vue.computed(() => {
      return localClient.newsletter === 'yes'
    })

    const sms = Vue.computed(() => {
      return localClient.sendSMS === 'yes'
    })

    const handleCheckboxChange = e => {
      localClient[e.target.id] = e.target.checked ? 'yes' : 'no'
    }

    Vue.watch(localCountry, (currentValue) => {
      localClient.country = currentValue.code
      localClient.country3 = currentValue.code_3
    }, { deep: true })

    Vue.watch(isMember, (currentValue) => {
      if (currentValue) {
        Object.assign(localClient, {
          title: AuthService.memberProfile.title == 'mr' ? 'Mr' : 'Mrs',
          firstName: AuthService.memberProfile.firstName,
          lastName: AuthService.memberProfile.lastName,
          email: AuthService.memberProfile.email,
          verifyEmail: AuthService.memberProfile.email,
          mobile: AuthService.memberProfile.phonenumber,
          country: AuthService.memberProfile.country,
        })
      }
    }, { deep: true })

    Vue.onMounted(async () => {
      document.querySelector('h1').scrollIntoView()
      try {
        if (!cart.selectedUnits || !cart.selectedUnits.value || !cart.rateId) {
          throw new AppError('unitOrRateMissing', ErrorLevel.WARNING)
        }
        Object.assign(localClient, cart.client.value)
        AuthService.on('authenticated', e => {
          isMember.value = !!e.detail.loyaltyId
        })
        if (AuthService.isMember) { isMember.value = true }
      } catch (err) {
        setErrorAndStopLoading(err)
      }

      if (AuthService.isAuthenticated) {
        isAuthenticated.value = true
        const { firstName, lastName, email } = AuthService
        Object.assign(localClient, { firstName, lastName, email, verifyEmail: email })
      }

      DATA_LAYER.bookingPersonalDetails()
    })

    return {
      cart,
      localClient,
      formErrors,
      isAuthenticated,
      isMember,
      newsletter,
      sms,
      onSubmitClient,
      handleCheckboxChange,
      PREFIX,
      WWW_HOME_URL,
      countryPrefix,
      setErrorAndStopLoading,
      localCountry,
      displayBirthdate,
      emailField,
      displayRegistrationPlate,
      formElement,
    }
  }
}
