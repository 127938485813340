export default {
  name: 'BookingAccessCode',
  template: `
    <label for="access-code">{{ $t('bookingModal.accessCode') }}</label>
    <div class="access-code__input">
      <input id="access-code" :placeholder="$t('bookingModal.accessCode')" type="text" v-model.lazy="bookingParams.accessCode" @focus="$emit('onFocus')" >
      <button @click="bookingParams.accessCode = ''" v-if="accessCodeInfo.isValid === false && bookingParams.accessCode !== ''"><i class="icon-close"></i></button>
      <i class="icon-check" v-if="accessCodeInfo.isValid && bookingParams.accessCode !== '' && bookingParams.accessCode === accessCodeInfo.code"></i>
    </div>
    <div v-show="accessCodeInfo.isValid === false && bookingParams.accessCode !== ''" class="access-code__warning">{{ $t('bookingModal.invalidAccessCode') }}</div>
    <div v-show="accessCodeInfo.isRemoved" class="access-code__warning">{{ $t('bookingModal.accessCodeRemoved') }}</div>
  `,
  emits: ['onFocus'],
  props: {
    accessCodeInfo: Object,
    bookingParams: Object,
  },
}
