const messagesSpecific = {
  en: {
    cancel: 'Cancel',
    back: 'Back',
    confirm: 'Confirm',

    // menu
    chooseAccomodation:  'Choose Accommodation',
    reservationDetails: 'Reservation Details',
    personalDetails: 'Personal Details',
    finalConfirmation: 'Final Confirmation',
    thankYou: 'Thank You For Your Reservation',

    fatalError: {
      title: 'We\'re sorry, but something went wrong.',
      message: 'Please go back and try again.',
    },

    contactSlug: "contact",

    reservationSummary: {
      title: 'Reservation summary',
      guests: 'guests',
      adults: '0 adults | 1 adult | {n} adults',
      children: '0 children | 1 child | {n} children',
      nights: ' | 1 night | {n} nights',
      selectRatePlan: 'Select rate plan',
      unitTotal: 'Unit total',
      total: 'Total',
      memberRate: 'Member rate',
      regularRate: 'Regular rate',
      youSave: 'As a member, you save',
      unit: 'Unit',
      removeUnit: 'Remove unit',
      lastAvailableUnit: 'You are booking the last available unit.',
      guestSufficiency: 'Sufficient units added, please continue. | You need to fit <b>one more guest</b> | You need to fit <b>{n} more guests</b>'
    },

    stepOne: {
      availableAccommodations: 'Available accommodations for',
      unavailableAccommodations : 'Accommodations not available for selected dates',
      continue: 'Continue',
      addUnitsToContinue: 'Add units to continue',
      addUnitsForMoreGuests: '| Add units for <b>1 more guest</b> | Add units for <b>{n} more guests</b>',
      discoverMore: 'Discover more',
      unitLimitReached: 'Online reservation is limited to 3 units. Contact us for larger groups',
      viewGallery: 'View gallery',
    },

    stepTwo: {
      selectedUnit: 'Selected unit',
      roomType: 'Room type',
      serviceType: 'Type of service',
      nights: 'Nights',
      adults: 'Adults',
      children: 'Children',
      services: 'Services',
      supplement: 'Supplement',
      rate: 'Rate',
      charge: 'Charge',
      qty: 'QTY',
      unitTotal: 'Unit total',
      close: 'CLOSE',
      updatePrice: 'Update price',
      continue: 'Continue',
      vehicle: 'Vehicle',
      vehicleType: 'Type',
      vehicleTypes: {
        CAMPING_CAR: 'Car and caravan',
        CARAVAN: 'Motorhome',
        TENT: 'Tent',
      },
      vehicleLength: 'Length',
    },

    stepThree: {
      reservationHolder: 'Reservation holder',
      title: 'Title*',
      mr: 'Mr',
      mrs: 'Mrs',
      name: 'Name*',
      lastName: 'Last Name*',
      email: 'Email*',
      verifyEmail: 'Verify Email*',
      country: 'Country*',
      phoneNumber: 'Phone number*',
      birthdate: 'Date of birth*',
      vehiclePlate: 'Vehicle number plate',
      commentsRequests: 'Comments and requests',
      subscribeNewsletter: 'Keep me updated on news, offers and exclusive member deals',
      confirmationSms: 'Send confirmation via SMS',
      continue: 'Continue',
      personalDetailsNote: 'Neither your e-mail address nor other information you give us will be disclosed or put at disposal of any third party. Please read our {privacyPolicy} to find out more about information privacy procedures.',
      privacyPolicy: 'Privacy policy',
    },

    PerStay: 'Price for the whole stay',
    PerNight: 'Price per night',
    PerPerson: 'Price per person',
    PerPersonNight: 'Price per person per night',
    PackageMin: 'Minimum price',

    ratePlan: {
      member: 'Member',
      regular: 'Regular',
      info: 'More information',
      minStay: 'Minimum stay',
      select: 'Select ',
      selected: 'Selected',
      nights: ' nights',
      rooms: 'unit',
      totalFor: 'Total for',
      memberPriceInfo: '<p>Join MaiStar Rewards Club easily while booking and discover all the benefits:</p><ul><li>Up to 20% discount for bookings via Maistra reservations center or maistra.com</li><li>Collect points and redeem exclusive rewards</li><li>Exclusive members-only offers</li><li>Your online account – everything in one place</li></ul><p>Membership in the program is free.</p>',
      ratePolicy: 'Rate Policy',
      salesPolicy: 'Sales policy',
      add: 'Add',
    },

    serviceType: {
      BedOnly: 'rent',
      BB: 'Bed & breakfast',
      HB: 'Half board',
      FB: 'Full board',
      AllInclusive: 'All inclusive',
      None: 'pitch rental',
      undefined: 'pitch rental'
    },

    chargeType: {
      PerNight: 'per day',
      PerPerson: 'per person',
      PerPersonNight:	'per person/day',
      PerStay: 'per service',
      PerUnit: 'per unit',
    },

    unitAvailable: {
      showRates: 'Show rates',
      selectRate: 'Select rate',
      hideRates: 'Hide rates',
      startingFrom: 'Starting from',
      regular: 'Regular',
      member: 'Member',
      sufficientUnitsAdded: 'Sufficient units are added. To add another unit, please, <a href=# class="add-guests">add more guests.</a>',
      minimumOccupancyRequired: 'Minimum occupancy is required. To add this unit, please, <a href=# class="add-guests">add more guests.</a>',
      unavailableRatePlan: 'Unit unavailable for this rate plan.',
      noMoreUnits: 'No more units available.',
      nrUnitsAvailable: 'No units left. | Only 1 unit left. | Only {n} units left.',
      unitLimitReached: 'Unit limit reached. Need more units? <a href="{url}">Contact us</a> for assistance.',
    },

    unitDemand: {
      unavailable: 'Reservations for selected unit at this moment are unavailible through online booking.',
      contactText: 'We kindly ask you to contact the Reservation department to make a reservation.',
      contactButton: 'Contact us',
      contactTextAdults: 'Bookings with the selected number of persons are only available through the Reservation Centre.',
    },

    // loader
    searching: 'Searching ...',

    stepFour: {
      NOGUARANTEE: 'No guarantee needed',
      BANKTRANSFER: 'Bank transfer',
      CCARD_GUARANTEE: 'Credit card gurarantee',
      CCARD_PAYMENT: 'Credit Card payment',
      INVOICE: 'Payment method: by invoice',
      VISA: 'Visa',
      VIELECTRON: 'Visa Electron',
      MASTERCARD: 'Master Card',
      AMEX: 'American Express',
      DINERS: 'Diners',
      reservationGuarantee: 'Reservation guarantee',
      guaranteeMethod: 'Guarantee method*',
      noGuarantee: 'No guarantee needed',
      additionalText: '',
      extraText: '',
      paymentCurrency: 'Payment currency',
      cardType: 'Card type*',
      cardNumber: 'Card number*',
      expirationMonth: 'Expiration month*',
      expirationYear: 'Expiration year*',
      cvv: 'CVN/CVC*',
      firstName: 'First Name*',
      lastName: 'Last Name*',
      fullAgreement: 'I have fully read and I accept the conditions.*',
      fullAgreementPopup: 'Sales policy',
      confirmBooking: 'Confirm Purchase',
      faq: {
        holder: {
          cta: 'Reservation holder',
          title: 'Reservation holder',
          description: `Reservation holder info
            In order to complete the reservation the client is required to submit personal information and credit card number, unless some other form of the booking guarantee is used. ( * ) indicates Required Fields.

            Arrival info/Flight details
            If booking transfer on arrival, please submit your arrival info including the arrival time, flight number and carrier. Otherwise this service will not be provided.`,
        },
        payment: {
          cta: 'How to pay',
          title: 'How to pay?',
          description: `Credit card guarantee - online
            The client is requested to submit a valid credit card number as a guarantee for his/her reservation.

            Guarantee prepayment
            If the client does not posses a credit card a guarantee prepayment can be made by your bank directly to the Maistra Hospitality Group account. In order to make such prepayment please print and fill in the supplied Payment form. This form contains complete information required by your bank in order to sucessfully forward the prepayment amount.

            The deadline and terms of such guarantee are defined within the Maistra Hospitality Group Service and Sales policy.

            Prepayment
            A partial or full prepayment might be requested for certain bookings, special packages or promotional rates. Such payments can be made online by filling the Credit Card Form, offline - by sending the Fax Credit Card Form, or by wire transfer directly to the Maistra Hospitality Group account.

            In case of prepayment request, the property will charge clients credit card after the booking has been confirmed and confirmation number issued. The Maistra Hospitality Group will handle such payments according to Terms and Conditions of Service and Sales policy.`,
        },
        immigration: {
          cta: 'Important immigration info',
          title: 'Important immigration info',
          description: `Nationals of some countries are required to submit a proof of secured accommodation when crossing the border.

            You can use the Reservation Confirmation form for such purposes. This form is sent to you after you have succesfully reserved your accommodation via our booking system and obtained the reservation code. You can review or reprint your Reservation Confirmation at any time by loging in to our booking system.

            Even though available in several languages, for simplicity’s sake please print the form in either the destination country’s language or in English.`,
        },
        confirmation: {
          cta: 'Reservation confirmation',
          title: 'Reservation confirmation',
          description: `Please verify your data
            Please check and verify all of the data before submitting.If you want to modify details of your reservation please click 'Edit'. To understand the cancellation policy and eventual cancellation penalties please read the Service and Sales policy.

            Confirm reservation
            Click 'Confirm reservation' to confirm and activate your reservation. Thus confirmed - the reservation can be cancelled or modified according to conditions of hotel Sales Policy. The reservation confirmation and code will be sent to you at your e-mail address.`,
        },
      },
      cvvPopUp: {
        title: 'What is cvn/cvc?',
        description: 'The Card Verification Number (CVN) or Card Verification Code (CVC), is an extra 3 or 4 digit code printed on your credit card.',
        paymentMethodsLeft: 'VISA, MASTERCARD & DINERS',
        paymentMethodsRight: 'AMERICAN EXPRESS',
        back: 'Back of card',
        backDescription: 'Three digits located to the right of the signature strip.',
        front: 'Front of card',
        frontDescription: 'Four digits located on either the left or the right side.',
      },
    },

    mustAgree: 'You must agree',
    bothRequired: 'Month and year field are both required.',
    mustBeInFuture: 'Expiration date (month/year) must be in the future',

    stepFive: {
      reservationCode: 'Your reservation code is',
      requestReceived: 'Your booking request has been received.',
      codeSent: 'Reservation code has been sent to',
      reservationHolder: 'Reservation holder',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phoneNumber: 'Phone number',
      guarantee: 'Guarantee',
      creditType: 'Credit Card type',
      expirationDate: 'Expiration Date',
      nameCard: 'Name on card',
      noGuarantee: 'No gurantee needed',
      cardNumber: 'Card number',
      cardType: 'Card type',
      printPage: 'Print this page',
      bookAnother: 'Book another',
      cancelReservation: 'Cancel reservation',
      mistakeVisits: 'You came to this page by mistake',
      checkReservation: 'If you booked a room previously, please check the email for details.',
    },

    linkBanner: {
      unlockBenefits: 'Unlock Exclusive Benefits with Our Loyalty Program',
      youEarn: 'With this stay you will earn',
      clickLink: 'Click the link that has been sent to <strong>{0} </strong> to activate your loyalty profile and start enjoying special perks.<br>Didn’t receive an email? <a href="https://www.maistra.com/loyalty/activation/">Request activation link.</a>',
      timeDisclaimer: 'Total amount of points based on your whole stay will be calculated and added 48h after checkout.',
      points: 'points',
    },

    loyaltyBenefits: {
      title: 'Benefits of loyalty profile:',
      list: '<li>Up to 20% discount for bookings via Maistra reservations center or maistra.com</li><li>Collect points and redeem exclusive rewards</li><li>Exclusive members-only offers</li><li>Your online account – everything in one place</li>',
    },

    seeYouSoon: 'See you soon!',

    // TODO errors
    unitOrRateMissing: 'user not selected unit or rate - go to step 1',
    personalDetailsMissing: 'user not entered personal details - go to step 3',
    paymentDetailsMissing: 'user not entered payment details - go to step 4',
    propertyIdMissing: 'At least propertyId should be set',
    tooManyGuests: 'The selected unit is not available for the requested number of people. Please try again with a different unit.',
    notEnoughAdults: `probably not enough adults to accommodate number of rooms and minAdults condition.
                      select different number of adults and children`,
    notAcceptingChildren: 'this room can\'t accept children, select a different room',
    missingPhobsId: 'probably missing phobsId in CMS.<br>Try another property.',
    phobs: 'error from PHOBS: errors are still to be defined.'
  },
  de: {
    cancel: 'Abbrechen',
    back: 'Zurück',
    confirm: 'Bestätigen',

    // menü
    chooseAccomodation:  'Unterkunft wählen',
    reservationDetails: 'Buchungsdetails',
    personalDetails: 'Persönliche Daten',
    finalConfirmation: 'Endgültige Bestätigung',
    thankYou: 'Vielen Dank für Ihre Buchung',

    fatalError: {
      title: 'Es tut uns leid, aber etwas ist schief gelaufen.',
      message: 'Bitte gehen Sie zurück und versuchen Sie es erneut.',
    },

    contactSlug: "kontakt",

    reservationSummary: {
      title: 'Buchung Ingesamt',
      guests: 'gäste',
      adults: '0 Erwachsener | 1 Erwachsene | {n} Erwachsene',
      children: '0 Kinder | 1 Kind | {n} Kinder',
      nights: ' | 1 Nacht | {n} Nächte',
      selectRatePlan: 'Preiskategorie auswählen',
      unitTotal: 'Einheit insgesamt',
      total: 'Insgesamt',
      memberRate: 'Mitgliedertarif',
      regularRate: 'Regulärer Preis',
      youSave: 'Sie sparen als Mitglied',
      unit: 'Einheit',
      removeUnit: 'Einheit entfernen',
      lastAvailableUnit: 'Sie buchen die letzte verfügbare Einheit.',
      guestSufficiency: 'Es wurden genügend Einheiten hinzugefügt, bitte fahren Sie fort. | Sie müssen <b>einen weiteren Gast</b> unterbringen | Sie müssen <b>{n} mehr Gäste</b> unterbringen'
    },

    stepOne: {
      availableAccommodations: 'Verfügbare Unterkünfte',
      unavailableAccommodations : 'Für ausgewählte Daten gibt es keine verfügbare Unterkünfte',
      continue: 'Fortsetzen',
      addUnitsToContinue: 'Einheiten zum Fortfahren hinzufügen',
      addUnitsForMoreGuests: '| Einheiten für <b>1 weiteren Gast</b> hinzufügen | Einheiten für <b>{n} weitere Gäste</b> hinzufügen',
      discoverMore: 'Mehr entdecken',
      unitLimitReached: 'Die Online-Buchung ist auf 3 Einheiten begrenzt. Kontaktieren Sie uns für größere Gruppen',
      viewGallery: 'Galerie ansehen',
    },

    stepTwo: {
      selectedUnit: 'Ausgewählte Einheit',
      roomType: 'Zimmertyp',
      serviceType: 'Dienstleistungsart',
      nights: 'Nächte',
      adults: 'Erwachsene',
      children: 'Kinder',
      services: 'Dienstleistungen',
      supplement: 'Aufpreis',
      rate: 'Preis',
      charge: 'Abrechnung',
      qty: 'Menge',
      unitTotal: 'Einheit insgesamt',
      close: 'SCHLIEßEN',
      updatePrice: 'Preis aktualisieren',
      continue: 'Fortsetzen',
      vehicle: 'Fahrzeug',
      vehicleType: 'Typ',
      vehicleTypes: {
        CAMPING_CAR: 'Auto und Wohnwagen',
        CARAVAN: 'Wohnmobil',
        TENT: 'Zelt',
      },
      vehicleLength: 'Länge',
    },

    stepThree: {
      reservationHolder: 'Buchungsinhaber',
      title: 'Anrede*',
      mr: 'Herr',
      mrs: 'Frau',
      name: 'Vorname*',
      lastName: 'Nachname*',
      email: 'E-mail*',
      verifyEmail: 'E-Mail bestätigen*',
      country: 'Land*',
      phoneNumber: 'Telefonnummer*',
      birthdate: 'Geburtsdatum*',
      vehiclePlate: 'Kraftfahrzeugkennzeichen',
      commentsRequests: 'Kommentare und Anfragen',
      subscribeNewsletter: 'Halten Sie mich auf dem Laufenden über Neuigkeiten, Angebote und exklusive Angebote für Mitglieder',
      confirmationSms: 'Bestätigung per SMS senden',
      continue: 'Fortsetzen',
      personalDetailsNote: 'Weder Ihre E-Mail-Adresse noch andere Informationen, die Sie uns mitteilen, werden weitergegeben oder Dritten zur Verfügung gestellt. Bitte lesen Sie unsere {privacyPolicy}, um mehr über die Datenschutzverfahren zu erfahren.',
      privacyPolicy: 'Datenschutzrichtlinie',
    },

    PerStay: 'Preis für den gesamten Aufenthalt',
    PerNight: 'Preis pro Nacht',
    PerPerson: 'Preis pro Person',
    PerPersonNight: 'Preis pro Person und Nacht',
    PackageMin: 'Minimaler Preis',

    ratePlan: {
      member: 'Mitglied',
      regular: 'Regulär',
      info: 'Mehr Informationen',
      minStay: 'Mindestaufenthalt',
      select: 'Auswählen ',
      selected: 'Ausgewählt',
      nights: ' nächte',
      rooms: 'einheit',
      totalFor: 'Insgesamt für',
      memberPriceInfo: '<p>Werde Mitglied des MaiStar Rewards Club´s während des Buchungsvorgangs, und entdecke alle seine Vorteile. </p><ul><li>Bis zu 20 % Rabatt auf Buchungen über dem Maistra Booking Center oder maistra.com</li><li>Sammeln Sie Punkte und lösen Sie exklusive Prämien ein </li><li>Exklusive Angebote nur für Mitglieder </li><li>Ihr Online-Konto – alles an einem Ort </li></ul><p>Die Mitgliedschaft im Programm ist kostenlos. </p>',
      ratePolicy: 'Preispolitik',
      salesPolicy: 'Allgemeine Geschäftsbedingungen',
      add: 'Hinzufügen',
    },

    serviceType: {
      BedOnly: 'Mieten',
      BB: 'Bed & Breakfast',
      HB: 'Halbpension',
      FB: 'Vollpension',
      AllInclusive: 'All Inclusive',
      None: 'Stellplatz mieten',
      undefined: 'Stellplatz mieten'
    },

    chargeType: {
      PerNight: 'pro Tag',
      PerPerson: 'pro Person',
      PerPersonNight:	'pro Person/Tag',
      PerStay: 'pro Dienstleistung',
      PerUnit: 'pro Einheit',
    },

    unitAvailable: {
      showRates: 'Preise zeigen',
      selectRate: 'Tarif auswählen',
      hideRates: 'Preise ausblenden',
      startingFrom: 'Ab',
      regular: 'Regulär',
      member: 'Mitglied',
      newPricingPolicy: 'Neue Preispolitik',
      pricePerPitch: 'Preis pro Stellplatz',
      upTo6People: 'bis zu 6 Personen',
      upTo4People: 'bis zu 4 Personen',
      upTo2People: 'bis zu 2 Personen',
      sufficientUnitsAdded: 'Es wurden ausreichend Einheiten hinzugefügt. Um eine weitere Einheit hinzuzufügen, fügen Sie bitte <a href=# class="add-guests">mehr Gäste hinzu.</a>',
      minimumOccupancyRequired: 'Eine Mindestbelegung ist erforderlich. Um diese Einheit hinzuzufügen, fügen Sie bitte <a href=# class="add-guests">mehr Gäste hinzu.</a>',
      unavailableRatePlan: 'Einheit nicht verfügbar für diesen Tarifplan.',
      noMoreUnits: 'Keine weiteren Einheiten verfügbar.',
      nrUnitsAvailable: 'Keine Einheiten übrig. | Nur noch 1 Einheit übrig. | Nur noch {n} Einheiten übrig.',
      unitLimitReached: 'Limit für die Einheit erreicht. Benötigen Sie mehr Einheiten? <a href="{url}">Kontaktieren Sie uns, </a>wenn Sie Hilfe benötigen.',
    },

    unitDemand: {
      unavailable: 'Buchungen für die ausgewählte Einheit sind derzeit nicht über die Online-Buchung verfügbar.',
      contactText: 'Wir bitten Sie, sich mit der Reservierungsabteilung in Verbindung zu setzen, um eine Buchung vorzunehmen.',
      contactButton: 'Kontaktieren Sie uns',
      contactTextAdults: 'Eine Reservierung für die ausgewählte Personenanzahl ist nur über die Reservierungsstelle möglich.',
    },

    // Wird geladen
    searching: 'Suche...',

    stepFour: {
      NOGUARANTEE: 'Keine Garantie erforderlich',
      BANKTRANSFER: 'Banküberweisung',
      CCARD_GUARANTEE: 'Kreditkartengarantie',
      CCARD_PAYMENT: 'Kreditkartenzahlung',
      INVOICE: 'Zahlungsart: per Rechnung',
      VISA: 'Visa',
      VIELECTRON: 'Visa Electron',
      MASTERCARD: 'Master Card',
      AMEX: 'American Express',
      DINERS: 'Diners',
      reservationGuarantee: 'Buchungsgarantie',
      guaranteeMethod: 'Garantiemethode*',
      noGuarantee: 'Keine Garantie erforderlich',
      additionalText: '',
      extraText: '',
      paymentCurrency: 'Zahlungswährung',
      cardType: 'Kartentyp*',
      cardNumber: 'Kartennummer*',
      expirationMonth: 'Ablaufmonat*',
      expirationYear: 'Ablaufjahr*',
      cvv: 'CVN/CVC*',
      firstName: 'Vorname*',
      lastName: 'Nachname*',
      fullAgreement: 'Ich habe die Bedingungen vollständig gelesen und akzeptiere sie.*',
      fullAgreementPopup: 'Verkaufspolitik',
      confirmBooking: 'Kauf Bestätigen',
      faq: {
        holder: {
          cta: 'Buchungsinhaber',
          title: 'Buchungsinhaber',
          description: `Informationen zum Buchungsinhaber
            In Um die Buchung abzuschließen, muss der Kunde persönliche Daten und Kreditkartennummer angeben, es sei denn, es wird eine andere Form der Buchungsgarantie verwendet. (*) zeigt Pflichtfelder an.

            Arrival Ankunftsinformationen/Flugdetails
            If Wenn Sie einen Transfer bei der Ankunft buchen, geben Sie bitte Ihre Ankunftsinformationen an, einschließlich Ankunftszeit, Flugnummer und Fluggesellschaft. Andernfalls wird dieser Service nicht angeboten.`,
        },
        payment: {
          cta: 'Wie man bezahlt',
          title: 'Wie bezahlt man?',
          description: `Kreditkartengarantie - online
            The der Kunde muss eine gültige Kreditkartennummer als Garantie für seine Buchung vorlegen.

            Guarantee vorauszahlung
            If Wenn der Kunde keine Kreditkarte hat, kann die Bank die Vorauszahlung direkt auf das Konto der Maistra Hospitality Group überweisen. Um eine solche Vorauszahlung zu leisten, drucken Sie bitte das mitgelieferte Zahlungsformular aus und füllen Sie es aus. Dieses Formular enthält alle Informationen, die Ihre Bank benötigt, um den Vorauszahlungsbetrag erfolgreich weiterzuleiten.

            The Frist und Bedingungen einer solchen Garantie sind in den Service- und Verkaufspolitik der Maistra Hospitality Group festgelegt.

            Prepayment
            A Für bestimmte Buchungen, Sonderpakete oder Aktionspreise kann eine teilweise oder vollständige Vorauszahlung erforderlich sein. Solche Zahlungen können online durch Ausfüllen des Kreditkartenformulars, offline durch Senden des Kreditkartenformulars per Fax oder per Überweisung direkt auf das Konto der Maistra Hospitality Group erfolgen.

            In Im Falle einer Vorauszahlung belastet das Objekt die Kreditkarte des Kunden, nachdem die Buchung bestätigt und die Bestätigungsnummer ausgestellt wurde. Die Maistra Hospitality Group wird solche Zahlungen gemäß den Allgemeinen Geschäftsbedingungen und der Verkaufspolitik abwickeln.`,
        },
        immigration: {
          cta: 'Wichtige Einreiseinformationen',
          title: 'Wichtige Einreiseinformationen',
          description: `Staatsangehörige einiger Länder müssen beim Grenzübertritt einen Nachweis über eine gesicherte Unterkunft vorlegen.

            You Sie können für solche Zwecke das Buchungsbestätigungsformular verwenden. Dieses Formular wird Ihnen zugesandt, nachdem Sie Ihre Unterkunft über unser Buchungssystem erfolgreich gebucht und den Buchungscode erhalten haben. Sie können Ihre Buchungsbestätigung jederzeit einsehen oder erneut ausdrucken, indem Sie sich in unser Buchungssystem einloggen.

            Even Obwohl das Formular in mehreren Sprachen verfügbar ist, drucken Sie das Formular der Einfachheit halber bitte entweder in der Sprache des Ziellandes oder auf Englisch aus.`,
        },
        confirmation: {
          cta: 'Buchungsbestätigung',
          title: 'Buchungsbestätigung',
          description: `Bitte überprüfen Sie Ihre Daten
            Please Bitte überprüfen und verifizieren Sie alle Daten vor dem Absenden. Wenn Sie Einzelheiten Ihrer Buchung ändern möchten, klicken Sie bitte auf 'Bearbeiten'. Um die Stornierungsbedingungen und eventuelle Stornierungsgebühren zu verstehen, lesen Sie bitte die Service- und Verkaufspolitik.

            Confirm Buchung bestätigen
            Click Klicken Sie auf 'Buchung bestätigen' um Ihre Buchung zu bestätigen und zu aktivieren. Sobald die Buchung bestätigt ist, kann sie gemäß den Bedingungen der Verkaufspolitik des Hotels storniert oder geändert werden. Die Buchungsbestätigung und der Buchungscode werden Ihnen an Ihre E-Mail-Adresse gesendet.`,
        },
      },
      cvvPopUp: {
        title: 'Was ist cvn/cvc?',
        description: 'Die Kartenprüfnummer (CVN) oder der Kartenprüfcode (CVC) ist ein zusätzlicher 3- oder 4-stelliger Code, der auf Ihrer Kreditkarte aufgedruckt ist.',
        paymentMethodsLeft: 'VISA, MASTERCARD & DINERS',
        paymentMethodsRight: 'AMERICAN EXPRESS',
        back: 'Kartenrückseite',
        backDescription: 'Drei Ziffern rechts vom Unterschriftsfeld.',
        front: 'Vorderseite der Karte',
        frontDescription: 'Vier Ziffern auf der linken oder rechten Seite.',
      },
    },

    mustAgree: 'Sie müssen zustimmen',
    bothRequired: 'Eingabefelder für Monat und Jahr sind erforderlich.',
    mustBeInFuture: 'Ablaufdatum (Monat/Jahr) muss in der Zukunft liegen',

    stepFive: {
      reservationCode: 'Ihr Buchungscode ist',
      requestReceived: 'Ihre Buchungsanfrage wurde empfangen.',
      codeSent: 'Der Buchungscode wurde gesendet an',
      reservationHolder: 'Buchungsinhaber',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-mail',
      phoneNumber: 'Telefonnummer',
      guarantee: 'Garantie',
      creditType: 'Kreditkartentyp',
      expirationDate: 'Ablaufdatum',
      nameCard: 'Name auf der Karte',
      noGuarantee: 'Keine Garantie erforderlich',
      cardNumber: 'Kartennummer',
      cardType: 'Kartentyp',
      printPage: 'Diese Seite drucken',
      bookAnother: 'Andere Unterkunft buchen',
      cancelReservation: 'Buchung stornieren',
      mistakeVisits: 'Sie sind aus Versehen auf diese Seite gelangt',
      checkReservation: 'Wenn Sie bereits ein Zimmer gebucht haben, überprüfen Sie bitte die E-Mail für weitere Einzelheiten.',
    },

    linkBanner: {
      unlockBenefits: 'Nutzen sie exklusive vorteile mit unserem treueprogramm',
      youEarn: 'Mit diesem Aufenthalt sammeln Sie',
      clickLink: 'Klicken Sie auf den Link, der an <strong>{0}</strong> gesendet wurde, um Ihr Treueprofil zu aktivieren und besondere Vergünstigungen zu genießen.<br> Sie haben keine E-Mail erhalten? <a href="https://www.maistra.com/de/loyalty/activation/">Aktivierungslink anfordern.</a>',
      timeDisclaimer: 'Die Gesamtpunktzahl für Ihren gesamten Aufenthalt wird berechnet und 48 Stunden nach dem Check-out hinzugefügt.',
      points: 'Punkte',
    },

    loyaltyBenefits: {
      title: 'MaiStar Rewards Club – Vorteile',
      list: '<li>Bis zu 20 % Rabatt auf Buchungen über dem Maistra Booking Center oder maistra.com</li><li>Sammeln Sie Punkte und erhalten Sie exklusive Prämien</li><li>Exklusive Members-Only-Angebote</li><li>Ihr Konto – alles an einem Ort</li>',
    },

    seeYouSoon: 'Bis bald!',

    // TODO Fehler
    unitOrRateMissing: 'der Benutzer hat die Einheit oder den Preis nicht ausgewählt - gehen Sie zu Schritt 1',
    personalDetailsMissing: 'der Benutzer hat keine persönlichen Daten eingegeben - gehen Sie zu Schritt 3',
    paymentDetailsMissing: 'der Benutzer hat keine Zahlungsdetails eingegeben - gehen Sie zu Schritt 4',
    propertyIdMissing: 'Mindestens propertyId sollte gesetzt werden',
    tooManyGuests: 'Die ausgewählte Einheit ist für die gewünschte Personenzahl nicht verfügbar. Bitte versuchen Sie es erneut mit einer anderen Einheit.',
    notEnoughAdults: `Wahrscheinlich wurden nicht genügend Erwachsene eingetragen, um in den augewählten Anzahl der Zimmer unterzubringen und die Bedingung minAdults zu erfüllen. select Wählen Sie unterschiedliche Anzahl von Erwachsenen und Kindern aus`,
    notAcceptingChildren: 'Dieses Zimmer kann keine Kinder aufnehmen, wählen Sie ein anderes Zimmer',
    missingPhobsId: 'Wahrscheinlich fehlt phobsId im CMS.<br>Wählen Sie ein anderes Objekt aus.',
    phobs: 'Fehler von PHOBS: Fehler müssen noch definiert werden.'
  },
  hr: {
    cancel: 'Otkaži',
    back: 'Natrag',
    confirm: 'Potvrdi',

    // izbornik
    chooseAccomodation:  'Odaberite smještaj',
    reservationDetails: 'Detalji rezervacije',
    personalDetails: 'Osobni podaci',
    finalConfirmation: 'Konačna potvrda',
    thankYou: 'Hvala vam na rezervaciji',

    fatalError: {
      title: 'Žao nam je, došlo je do pogreške.',
      message: 'Molimo vratite se na prethodnu stranicu i pokušajte ponovno.',
    },

    contactSlug: "kontakt",

    reservationSummary: {
      title: 'Sažetak rezervacije',
      guests: 'gosti',
      adults: '0 odrasle osobe | 1 odrasla osoba | {n} odrasle osobe',
      children: '0 djece | 1 dijete | {n} djece',
      nights: ' | 1 noćenje | {n} noćenja',
      selectRatePlan: 'Odaberite kategoriju cijene',
      unitTotal: 'Jedinica ukupno',
      total: 'Ukupno',
      memberRate: 'Cijene za članove',
      regularRate: 'Standardna cijena',
      youSave: 'Vaša ušteda kao član',
      unit: 'Jedinica',
      removeUnit: 'Ukloni jedinicu',
      lastAvailableUnit: 'Upravo rezervirate posljednju dostupnu jedinicu.',
      guestSufficiency: 'Dodano je dovoljno jedinica, nastavite. | Trebate smjestiti <b>još jednog gosta</b> | Trebate smjestiti još sljedeći <b>broj gostiju: {n}</b>'
    },

    stepOne: {
      availableAccommodations: 'Dostupni smještaji',
      unavailableAccommodations : 'Smještaji nisu dostupni za odabrane datume',
      continue: 'Nastavi',
      addUnitsToContinue: 'Dodajte jedinice za nastavak',
      addUnitsForMoreGuests: '| Dodajte jedinice za <b>još jednog gosta</b> | Dodajte jedinice za <b>{n} više gostiju</b>',
      discoverMore: 'Otkrijte više',
      unitLimitReached: 'Rezervacija putem interneta ograničena je na 3 jedinice. Obratite nam se za veće skupine',
      viewGallery: 'Pogledajte galeriju',
    },

    stepTwo: {
      selectedUnit: 'Odabrana jedinica',
      roomType: 'Vrsta sobe',
      serviceType: 'Vrsta usluge',
      nights: 'Noći',
      adults: 'Odrasli',
      children: 'Djeca',
      services: 'Usluge',
      supplement: 'Doplata',
      rate: 'Cijena',
      charge: 'Naplata',
      qty: 'Količina',
      unitTotal: 'Jedinica ukupno',
      close: 'ZATVORI',
      updatePrice: 'Ažuriraj cijenu',
      continue: 'Nastavi',
      vehicle: 'Vozilo',
      vehicleType: 'Tip',
      vehicleTypes: {
        CAMPING_CAR: 'Automobil i kamp-prikolica',
        CARAVAN: 'Karavan',
        TENT: 'Šator',
      },
      vehicleLength: 'Dužina',
    },

    stepThree: {
      reservationHolder: 'Nositelj rezervacije',
      title: 'Titula*',
      mr: 'Gospodin',
      mrs: 'Gospođa',
      name: 'Ime*',
      lastName: 'Prezime*',
      email: 'E-pošta*',
      verifyEmail: 'Potvrdite e-poštu*',
      country: 'Država*',
      phoneNumber: 'Broj telefona*',
      birthdate: 'Datum rođenja*',
      vehiclePlate: 'Registarski broj vozila',
      commentsRequests: 'Komentari i zahtjevi',
      subscribeNewsletter: 'Želim primati obavijesti o novostima, ponudama i ekskluzivnim ponudama za članove',
      confirmationSms: 'Pošalji potvrdu putem SMS-a',
      continue: 'Nastavi',
      personalDetailsNote: 'Vaša adresa e-pošte i ostali podaci koje navedete neće se otkriti nijednoj trećoj strani niti joj se staviti na raspolaganje. Pročitajte naša {privacyPolicy} da biste saznali više o postupcima zaštite podataka.',
      privacyPolicy: 'Pravila o privatnosti',
    },

    PerStay: 'Cijena za cijeli boravak',
    PerNight: 'Cijena po noći',
    PerPerson: 'Cijena po osobi',
    PerPersonNight: 'Cijena po osobi i po noći',
    PackageMin: 'Minimalna cijena',

    ratePlan: {
      member: 'Član',
      regular: 'Standardno',
      info: 'Više informacija',
      minStay: 'Minimalni boravak',
      select: 'Odaberi ',
      selected: 'Odabrano',
      nights: ' noći',
      rooms: 'jedinica',
      totalFor: 'Ukupno za',
      memberPriceInfo: '<p>Prilikom rezervacije smještaja pridružite se MaiStar Rewards Club-u i otkrijte sve pogodnosti: </p><ul><li>Do 20 % popusta na rezervacije putem Maistrina centra za rezervacije ili internetske stranice maistra.com</li><li>skupljajte bodove i osvojite ekskluzivne nagrade </li><li>ekskluzivne ponude samo za članove </li><li>vaš online račun – sve na jednom mjestu </li></ul><p>Članstvo u programu je besplatno. </p>',
      ratePolicy: 'Pravilnik o cijenama',
      salesPolicy: 'Opći uvjeti poslovanja',
      add: 'Dodaj',
    },

    serviceType: {
      BedOnly: 'najam',
      BB: 'Noćenje s doručkom',
      HB: 'Polupansion',
      FB: 'Puni pansion',
      AllInclusive: 'All inclusive',
      None: 'najam parcele',
      undefined: 'najam parcele'
    },

    chargeType: {
      PerNight: 'po danu',
      PerPerson: 'po osobi',
      PerPersonNight:	'po osobi/danu',
      PerStay: 'po usluzi',
      PerUnit: 'po jedinici',
    },

    unitAvailable: {
      showRates: 'Prikaži cijene',
      selectRate: 'Odaberi cijenu',
      hideRates: 'Sakrij cijene',
      startingFrom: 'Od',
      regular: 'Standardno',
      member: 'Član',
      newPricingPolicy: 'Nova politika cijena',
      pricePerPitch: 'Cijena po parceli',
      upTo6People: 'do 6 osoba',
      upTo4People: 'do 4 osoba',
      upTo2People: 'do 2 osoba',
      sufficientUnitsAdded: 'Dodano je dovoljno jedinica. Za dodavanje još jedne jedinice <a href=# class="add-guests">dodajte više gostiju.</a>',
      minimumOccupancyRequired: 'Obvezna je minimalna popunjenost. Za dodavanje ove jedinice <a href=# class="add-guests">dodajte više gostiju.</a>',
      unavailableRatePlan: 'Jedinica nije dostupna za ovaj cjenovni plan.',
      noMoreUnits: 'Nema više dostupnih jedinica.',
      nrUnitsAvailable: 'Nema dostupnih jedinica. | Dostupna je samo 1 jedinica. | Dostupne su samo {n} jedinice.',
      unitLimitReached: 'Dosegnuto je ograničenje jedinica. Trebate više jedinica? <a href="{url}">Obratite nam se</a> za pomoć.',
    },

    unitDemand: {
      unavailable: 'Trenutačno nije moguće rezervirati odabranu jedinicu putem interneta.',
      contactText: 'Obratite se Odjelu za rezervacije da biste izvršili rezervaciju.',
      contactButton: 'Obratite nam se',
      contactTextAdults: 'Za odabrani broj gostiju rezervacije su moguće samo putem rezervacijskog centra.',
    },

    // učitavanje
    searching: 'Pretraživanje...',

    stepFour: {
      NOGUARANTEE: 'Garancija nije potrebna',
      BANKTRANSFER: 'Bankovni prijenos',
      CCARD_GUARANTEE: 'Garancija putem kreditne kartice',
      CCARD_PAYMENT: 'Plaćanje putem kreditne kartice',
      INVOICE: 'Način plaćanja: putem računa',
      VISA: 'Visa',
      VIELECTRON: 'Visa Electron',
      MASTERCARD: 'MasterCard',
      AMEX: 'American Express',
      DINERS: 'Diners',
      reservationGuarantee: 'Garancija rezervacije',
      guaranteeMethod: 'Način garancije*',
      noGuarantee: 'Garancija nije potrebna',
      additionalText: '',
      extraText: '',
      paymentCurrency: 'Valuta plaćanja',
      cardType: 'Vrsta kartice*',
      cardNumber: 'Broj kartice*',
      expirationMonth: 'Mjesec isteka*',
      expirationYear: 'Godina isteka*',
      cvv: 'CVN/CVC*',
      firstName: 'Ime*',
      lastName: 'Prezime*',
      fullAgreement: 'Pročitao/la sam uvjete u potpunosti i prihvaćam ih.*',
      fullAgreementPopup: 'Prodajna politika',
      confirmBooking: 'Potvrdi kupnju',
      faq: {
        holder: {
          cta: 'Nositelj rezervacije',
          title: 'Nositelj rezervacije',
          description: `Podaci o nositelju rezervacije
            In Da bi dovršio rezervaciju, klijent mora unijeti osobne podatke i broj kreditne kartice, osim ako se ne upotrebljava neki drugi oblik garancije rezervacije. Polja označena zvjezdicom (*) su obvezna.

            Arrival Podaci o dolasku/letu
            If Ako rezervirate prijevoz prilikom dolaska, navedite podatke o dolasku, uključujući vrijeme dolaska, broj leta i zračnog prijevoznika. U suprotnom vam se ova usluga neće pružiti.`,
        },
        payment: {
          cta: 'Kako platiti',
          title: 'Kako platiti?',
          description: `Garancija putem kreditne kartice - putem interneta
            The Klijent mora navesti broj važeće kreditne kartice kao garanciju za svoju rezervaciju.

            Guarantee Garancijski polog
            If Ako klijent nema kreditnu karticu, banka može uplatiti garancijski polog izravno na račun društva Maistra Hospitality Group. Da bi bilo moguće uplatiti takav polog, ispišite i ispunite Obrazac za plaćanje. Na ovom su obrascu navedene sve informacije koje su vašoj banci potrebne za uspješno prosljeđivanje iznosa pologa.

            The Rok i uvjeti takve garancije određeni su u Pravilima o pružanju usluga i prodaji društva Maistra Hospitality Group.

            Prepayment
            A Djelomično ili potpuno plaćanje unaprijed može se zatražiti za određene rezervacije, posebne pakete ili promotivne cijene. Takva se plaćanja mogu izvršiti putem interneta tako da se ispuni Obrazac za podatke o kreditnoj kartici, izvan mreže tako da se Obrazac za podatke o kreditnoj kartici pošalje putem telefaksa ili putem elektroničkog prijenosa novca izravno na račun društva Maistra Hospitality Group.

            In U slučaju zahtjeva za plaćanje unaprijed objekt će teretiti kreditnu karticu klijenta nakon što se rezervacija potvrdi i nakon što se izda broj potvrde. Društvo Maistra Hospitality Group obrađivat će takva plaćanja u skladu s Uvjetima i odredbama navedenima u Pravilima o pružanju usluga i prodaji.`,
        },
        immigration: {
          cta: 'Važne informacije o ulasku u državu',
          title: 'Važne informacije o ulasku u državu',
          description: `Državljani nekih zemalja moraju predočiti dokaz o osiguranom smještaju prilikom prelaska granice.

            You Za te svrhe možete upotrijebiti Obrazac s potvrdom rezervacije. Ovaj vam se obrazac šalje nakon uspješne rezervacije smještaja putem našeg sustava za rezervacije i nakon što dobijete šifru rezervacije. U svakom trenutku možete pregledati ili ponovno ispisati potvrdu rezervacije tako da se prijavite u naš sustav za rezervacije.

            Even Iako je obrazac dostupan na nekoliko jezika, radi jednostavnosti ispišite obrazac na jeziku države u koju putujete ili na engleskom jeziku.`,
        },
        confirmation: {
          cta: 'Potvrda rezervacije',
          title: 'Potvrda rezervacije',
          description: `Provjerite svoje podatke
            Please Provjerite i potvrdite sve podatke prije slanja. Ako želite izmijeniti detalje rezervacije, kliknite „Uredi”. Za razumijevanje pravila otkazivanja i mogućih naknada za otkazivanje pročitajte Pravila o pružanju usluga i prodaji.

            Confirm Potvrdi rezervaciju
            Click Kliknite „Potvrdi rezervaciju” da biste potvrdili i aktivirali rezervaciju. Nakon što se rezervacija potvrdi, može se otkazati ili izmijeniti u skladu s uvjetima Prodajne politike hotela. Potvrda i šifra rezervacije poslat će vam se na vašu adresu e-pošte.`,
        },
      },
      cvvPopUp: {
        title: 'Što je CVN/CVC?',
        description: 'Broj za provjeru kartice (CVN) ili šifra za provjeru kartice (CVC) dodatna je troznamenkasta ili četveroznamenkasta šifra koja je otisnuta na vašoj kreditnoj kartici.',
        paymentMethodsLeft: 'VISA, MASTERCARD I DINERS',
        paymentMethodsRight: 'AMERICAN EXPRESS',
        back: 'Stražnja strana kartice',
        backDescription: 'Tri znamenke koje se nalaze s desne strane trake s potpisom.',
        front: 'Prednja strana kartice',
        frontDescription: 'Četiri znamenke koje se nalaze na lijevoj ili desnoj strani.',
      },
    },

    mustAgree: 'Morate prihvatiti',
    bothRequired: 'Polja za unos mjeseca i godine su obavezna.',
    mustBeInFuture: 'Datum isteka (mjesec/godina) mora biti u budućnosti',

    stepFive: {
      reservationCode: 'Vaša šifra rezervacije je',
      requestReceived: 'Vaš zahtjev za rezervaciju je primljen.',
      codeSent: 'Šifra rezervacije poslana je na',
      reservationHolder: 'Nositelj rezervacije',
      firstName: 'Ime',
      lastName: 'Prezime',
      email: 'E-pošta',
      phoneNumber: 'Broj telefona',
      guarantee: 'Garancija',
      creditType: 'Vrsta kreditne kartice',
      expirationDate: 'Datum isteka',
      nameCard: 'Ime i prezime na kartici',
      noGuarantee: 'Garancija nije potrebna',
      cardNumber: 'Broj kartice',
      cardType: 'Vrsta kartice',
      printPage: 'Ispiši ovu stranicu',
      bookAnother: 'Rezerviraj drugi smještaj',
      cancelReservation: 'Otkaži rezervaciju',
      mistakeVisits: 'Došli ste na ovu stranicu pogreškom',
      checkReservation: 'Ako ste ranije rezervirali sobu, provjerite e-poštu za detalje.',
    },

    linkBanner: {
      unlockBenefits: 'Ostvarite ekskluzivne pogodnosti uz naš program vjernosti',
      youEarn: 'Za ovaj boravak dobit ćete',
      clickLink: 'Kliknite na link koji je poslan na adresu <strong>{0}</strong> kako biste aktivirali svoj profil vjernosti i počeli koristiti posebne povlastice.<br> Niste primili poruku e-pošte? <a href="https://www.maistra.com/hr/loyalty/activation/">Zatražite poveznicu za aktivaciju.</a>',
      timeDisclaimer: 'Ukupan broj bodova na temelju vašeg cijelog boravka izračunat će se i dodati 48 sati nakon odjave.',
      points: 'bodova',
    },

    loyaltyBenefits: {
      title: 'MaiStar Rewards Club pogodnosti',
      list: '<li>Do 20 % popusta na rezervacije putem Maistrina centra za rezervacije ili internetske stranice maistra.com</li><li>Prikupljajte bodove i ostvarujte ekskluzivne nagrade</li><li>Ekskluzivne ponude „samo za članove”</li><li>Vaš račun – sve na jednom mjestu</li>',
    },

    seeYouSoon: 'Vidimo se uskoro!',

    // TODO pogreške
    unitOrRateMissing: 'korisnik nije odabrao jedinicu ili cijenu - idite na 1. korak',
    personalDetailsMissing: 'korisnik nije unio osobne podatke - idite na 3. korak',
    paymentDetailsMissing: 'korisnik nije unio podatke o plaćanju - idite na 4. korak',
    propertyIdMissing: 'Potrebno je postaviti barem propertyId',
    tooManyGuests: 'Odabrana jedinica nije dostupna za zatraženi broj ljudi. Pokušajte ponovno s drugom jedinicom.',
    notEnoughAdults: `Vjerojatno nije unesen dovoljan broj odraslih za smještaj u odabranom broju soba i nije ispunjen uvjet minAdults.
                      select Odaberite drugi broj odraslih i djece`,
    notAcceptingChildren: 'ova soba nije namijenjena za djecu, odaberite drugu sobu',
    missingPhobsId: 'Vjerojatno nedostaje phobsId u CMS-u.<br>Odaberite drugi objekt.',
    phobs: 'pogreška iz PHOBS-a: pogreške još nisu definirane.'
  },
  it: {
    cancel: 'Annulla',
    back: 'Indietro',
    confirm: 'Confermare',
    chooseAccomodation: 'Scegli Alloggio',
    reservationDetails: 'Dettagli della prenotazione',
    personalDetails: 'Dati personali',
    finalConfirmation: 'Conferma finale',
    thankYou: 'Grazie per la prenotazione',

    fatalError: {
      title: 'Ci dispiace qualcosa è andato storto.',
      message: 'Per favore, torna indietro e riprova.',
    },

    contactSlug: "contact",

    reservationSummary: {
      title: 'Riepilogo prenotazione',
      guests: 'ospiti',
      adults: '0 adulti | 1 audolto | {n} adulti',
      children: '0 bambini | 1 bambino | {n} bambini',
      nights: ' | 1 notte | {n} notti',
      selectRatePlan: 'Seleziona il piano tariffario',
      unitTotal: 'Totale unità',
      total: 'Totale',
      memberRate: 'Tariffa affiliato’',
      regularRate: 'Tariffa regolare',
      youSave: 'Risparmia come affiliato',
      unit: 'Unità',
      removeUnit: 'Rimuovi unità',
      lastAvailableUnit: 'Stai acquistando l’ultima unità disponibile.',
      guestSufficiency: 'È stato aggiunto un numero sufficiente di unità, continua. | Devi inserire <b>un altro ospite</b> | Devi inserire <b>{n} più ospiti</b>'
    },
    stepOne: {
      availableAccommodations: 'Alloggi disponibili',
      unavailableAccommodations: 'Alloggi non disponibili per le date selezionate',
      continue: 'Continua',
      addUnitsToContinue: 'Aggiungi unità per continuare',
      addUnitsForMoreGuests: '| Aggiungi unità per <b>un altro ospite</b> | Aggiungi unità per <b>per altri {n} ospiti</b>',
      discoverMore: 'Scopri di più',
      unitLimitReached: 'La prenotazione online è limitata a 3 unità. Contattaci per gruppi più grandi',
      viewGallery: 'Guarda la galleria',
    },
    stepTwo: {
      selectedUnit: 'Unità selezionata',
      roomType: 'Tipo di stanza',
      serviceType: 'Tipo di servizio',
      nights: 'Notti',
      adults: 'Adulti',
      children: 'Bambini',
      services: 'Servizi',
      supplement: 'Supplemento',
      rate: 'Valutare',
      charge: 'Caricare',
      qty: 'QTÀ',
      unitTotal: 'Totale unità',
      close: 'CHIUDERE',
      updatePrice: 'Aggiorna prezzo',
      continue: 'Continua',
      vehicle: 'Veicolo',
      vehicleType: 'Tipo',
      vehicleTypes: {
        CAMPING_CAR: 'Automobile e roulotte',
        CARAVAN: 'Camper',
        TENT: 'Tenda',
      },
      vehicleLength: 'Lunghezza',
    },
    stepThree: {
      reservationHolder: 'Titolare della prenotazione',
      title: 'Titolo*',
      mr: 'Sig',
      mrs: 'Sig.ra',
      name: 'Nome*',
      lastName: 'Cognome*',
      email: 'E-mail*',
      verifyEmail: 'Verifica Email*',
      country: 'Paese*',
      phoneNumber: 'Numero di telefono*',
      birthdate: 'Data di nascita*',
      vehiclePlate: 'Il numero di targa del veicolo',
      commentsRequests: 'Commenti e richieste',
      subscribeNewsletter: 'Tenetemi aggiornato/a sulle novità, offerte e affari speciali per affiliati',
      confirmationSms: 'Invia conferma via SMS',
      continue: 'Continua',
      personalDetailsNote: 'Né il Suo indirizzo e-mail né altre informazioni che ci fornirà non saranno divulgate o messe a disposizione di terzi. Si prega di leggere la nostra {privacyPolicy} per saperne di più sulle procedure della politica sulla privacy.',
      privacyPolicy: 'Informativa sulla privacy',
    },
    PerStay: "Prezzo per l'intero soggiorno",
    PerNight: 'Prezzo per notte',
    PerPerson: 'Prezzo a persona',
    PerPersonNight: 'Prezzo per persona per notte',
    PackageMin: 'Prezzo minimo',
    ratePlan: {
      member: 'Affiliato',
      regular: 'Regolare',
      info: 'Maggiori informazioni',
      minStay: 'Soggiorno minimo',
      select: 'Selezionare ',
      selected: 'Selezionato',
      nights: ' notti',
      rooms: 'unità',
      totalFor: 'Totale per',
      memberPriceInfo: '<p>Iscriviti facilmente al MaiStar Rewards Club durante la prenotazione e scopri tutti i vantaggi </p><ul><li>Fino al 20% di sconto per prenotazioni effettuate tramite il centro prenotazioni Maistra o maistra.com</li><li>Accumula i punti e riscatta premi esclusivi </li><li>Offerte esclusive riservate agli affiliati. </li><li>Il tuo account on-line – tutto in un unico posto </li></ul><p>L\'adesione al programma è gratuita </p>',
      ratePolicy: 'Politica tariffaria',
      salesPolicy: 'Condizioni generali',
      add: 'Aggiungi',
    },
    serviceType: {
      BedOnly: 'Affito',
      BB: 'Pernottamento con prima colazione',
      HB: 'Mezza pensione',
      FB: 'Pensione completa',
      AllInclusive: 'All inclusive',
      None: 'affitto piazzola',
      undefined:  'affitto piazzola'
    },
    chargeType: {
      PerNight: 'per giorno',
      PerPerson: 'per persona',
      PerPersonNight:	'per persona/giorno',
      PerStay: 'per servizio',
      PerUnit: 'per unità',
    },
    unitAvailable: {
      showRates: 'Mostra tariffe',
      selectRate: 'Seleziona la tariffa',
      hideRates: 'Nascondi tariffe',
      startingFrom: 'Partendo da',
      regular: 'Regolare',
      member: 'Affiliato',
      newPricingPolicy: 'Nuova politica dei prezzi',
      pricePerPitch: 'Prezzo per piazzola',
      upTo6People: 'fino a 6 persone',
      upTo4People: 'fino a 4 persone',
      upTo2People: 'fino a 2 persone',
      sufficientUnitsAdded: 'È stato aggiunto un numero sufficiente di unità. Per aggiungere un’altra unità <a href=# class="add-guests">aggiungi più ospiti.</a>',
      minimumOccupancyRequired: 'È richiesto un numero minimo di occupanti. Per aggiungere questa unità <a href=# class="add-guests">aggiungi più ospiti.</a>',
      unavailableRatePlan: 'Unità non disponibile per questo piano tariffario.',
      noMoreUnits: 'Non ci sono più unità disponibili.',
      nrUnitsAvailable: 'Nessuna unità rimasta. | Solo 1 unità rimasta. | Solo {n} unità rimaste.',
      unitLimitReached: 'È stato raggiunto il limite di unità. Hai bisogno di più unità? <a href="{url}">Contattaci</a> per avere assistenza.',
    },
    unitDemand: {
      unavailable: "Le prenotazioni per l'unità selezionata in questo momento non sono disponibili tramite prenotazione online.",
      contactText: "Ti chiediamo gentilmente di contattare l'ufficio Prenotazioni per effettuare una prenotazione.",
      contactButton: 'Contattaci',
      contactTextAdults: 'La prenotazione per il numero di persone selezionato è disponibile soltanto tramite il centro prenotazioni.',
    },
    searching: 'Ricerca...',
    stepFour: {
      NOGUARANTEE: 'Nessuna garanzia necessaria',
      BANKTRANSFER: 'Bonifico bancario',
      CCARD_GUARANTEE: 'Garanzia con carta di credito',
      CCARD_PAYMENT: 'Pagamento con carta di credito',
      INVOICE: 'Metodo di pagamento: su fattura',
      VISA: 'Visa',
      VIELECTRON: 'Visa Electron',
      MASTERCARD: 'Master Card',
      AMEX: 'American Express',
      DINERS: 'Diners',
      reservationGuarantee: 'Garanzia di prenotazione',
      guaranteeMethod: 'Metodo di garanzia*',
      noGuarantee: 'Nessuna garanzia necessaria',
      additionalText: '',
      extraText: '',
      paymentCurrency: 'Valuta del pagamento',
      cardType: 'Tipo di carta*',
      cardNumber: 'Numero di carta*',
      expirationMonth: 'Mese di scadenza*',
      expirationYear: 'Anno di scadenza*',
      cvv: 'CVN/CVC*',
      firstName: 'Nome*',
      lastName: 'Cognome*',
      fullAgreement: "Ho letto integralmente e accetto le condizioni.*",
      fullAgreementPopup: 'Politica di vendita',
      confirmBooking: 'Conferma l’acquisto',
      faq: {
        holder: {
          cta: 'Titolare della prenotazione',
          title: 'Utente della prenotazione',
          description: `Info sull'utente della prenotazione
            Per completare la prenotazione, l'utente deve rilasciare i propri dati personali ed eventualmente il numero della Carta di Credito, qualora non utilizzasse altre forme di garanzia della prenotazione. I campi segnati con asterisco (*) sono obbligatori.

            Detagli sull'arrivo/volo
            Se l’ospite ha prenotato il servizio navetta all'arrivo, è necessario inserire i dati sulla modalità e sull'ora di arrivo (numero di volo /viaggio e nome della copmpagnia), altrimenti il servizio di transfer non verrà effettuato.`,
        },
        payment: {
          cta: 'Come pagare',
          title: 'Come pagare?',
          description: `Garanzia con carta di credito - online
            Il cliente è invitato a presentare un numero valido della carta di credito alla garanzia per la sua prenotazione.

            Garanzia tramite deposito bancario
            Qualora l'ospite non possiede la Carta di Credito [può effettuare il pagamento della caparra confirmatoria tramite la sua banca direttamente sul conto di Maistra Hospitality Group. Per poter effettuare tale pagamento è necessario compilare il Modulo di pagamento e presentarlo nellla banca che effettuerà il trasferimento.
            Il pagamento della caparra confirmatoria con queste modalità è possibile soltanto nei termini indicati da Maistra Hospitality Group nelle sue Condizioni generali.

            Prenotazioni con pagamento anticipato
            Per alcune prenotazioni o pacchetti speciali viene richiesto il pagamento parziale o totale anticipato, prima dell'arrivo. E' possibile effettuare tale pagamento online con la Carta di Credito, offline con la Carta di Credito via fax o con pagamento tramite bonificio bancario.

            Nel caso in cui è necessario pagare la prenotazione in anticipo , Maistra Hospitality Group farà l’addebito sulla Carta dell'ospite subito dopo la prenotazione effettuata. La restituzione o la trattenuta della parte dell'anticipo pagato sono regolati dalle Condizioni generali di Maistra Hospitality Group.`,
        },
        immigration: {
          cta: 'Informazioni importanti sull\'immigrazione',
          title: 'Info immigrazione',
          description: `Info immigrazione!

            Nel momento del passaggio del confine ad alcuni cittadini viengono richiesti i dati sulla sistemazione organizzata nel paese in cui stanno per entrare.

            A questo scopo è possibile utilizzare il documento di conferma della prenotazione, che si ottiene dopo aver effettuato la prenotazione dal nostro sistema di prenotazioni e dopo aver ottenuto il codice di prenotazione.

            In ogni istante è possibile accedere al nostro sistema di prenotazioni e contollare e stampare la prenotazione effettuata.

            Nonostante sia possibile stampare la 'Conferma della prenotazione' in diverse lingue, è consigliabile stampare la conferma della prenotazione nella lingua del paese in cui state per entrare o in inglese.`,
        },
        confirmation: {
          cta: 'Conferma della prenotazione',
          title: 'Conferma della prenotazione',
          description: `Verificare i propri dati!
            Prima della conferma finale prego verificare tutti i dati inseriti nella prenotazione. Qualora si volessero modificare i dati cliccare su 'Modifica' accanto al dato da modificare. Per capire meglio la cancellazione della prenotazione leggere la Condizioni generali.

            Effettua la prenotazione
            Cliccando su 'Prenota' si effettua la prenotazione automatica nell'hotel prescelto. E' possibile cancellare o modificare la prenotazione effettuata soltanto se lo permettono le Condizioni generali dell'hotel. La conferma ufficiale e il numero della prenotazione saranno inviati alla Sua e-mail.`,
        },
      },
      cvvPopUp: {
        title: "Cos'è CVN / CVC?",
        description: 'Il numero di verifica della scheda (CVN) o il codice di verifica della scheda (CVC), è un codice extra di 3 o 4 cifre stampato sulla carta di credito.',
        paymentMethodsLeft: 'VISA, MASTERCARD & DINERS',
        paymentMethodsRight: 'AMERICAN EXPRESS',
        back: 'Indietro della carta',
        backDescription: 'Tre cifre situate a destra della striscia di firma.',
        front: 'Parte anteriore della carta',
        frontDescription: 'Quattro cifre situate sulla sinistra o sul lato destro.',
      },
    },
    mustAgree: "Devi essere d'accordo",
    bothRequired: "I campi del mese e dell'anno sono entrambi obbligatori.",
    mustBeInFuture: 'La data di scadenza (mese/anno) deve essere futura',
    stepFive: {
      reservationCode: 'Il tuo codice di prenotazione è',
      requestReceived: 'La tua richiesta di prenotazione è stata ricevuta.',
      codeSent: 'Il codice di prenotazione è stato inviato a',
      reservationHolder: 'Titolare della prenotazione',
      firstName: 'Nome di battesimo',
      lastName: 'Cognome',
      email: 'E-mail',
      phoneNumber: 'Numero di telefono',
      guarantee: 'Garanzia',
      creditType: 'Tipo di carta di credito',
      expirationDate: 'Data di scadenza',
      nameCard: 'nome sulla carta',
      noGuarantee: 'Nessuna garanzia necessaria',
      cardNumber: 'Numero di carta',
      cardType: 'Tipo di carta',
      printPage: 'stampa questa pagina',
      bookAnother: 'Prenota un altro',
      cancelReservation: 'Annulla prenotazione',
      mistakeVisits: 'Sei arrivato a questa pagina per errore',
      checkReservation: "Se hai prenotato una camera in precedenza, controlla l'e-mail per i dettagli.",
    },

    linkBanner: {
      unlockBenefits: 'Sblocchi vantaggi esclusivi con maistar rewards',
      youEarn: 'Con questo soggiorno guadagnerà',
      clickLink: 'Clicchi sul link inviato a <strong>{0}</strong> per attivare il Suo profilo del programma fedeltà e cominci a godere di esclusivi vantaggi.<br> Non ha ancora ricevuto l’email? <a href="https://www.maistra.com/it/loyalty/activation/">Richieda il link di attivazione .</a>',
      timeDisclaimer: 'L’importo totale di punti basato sul Suo intero soggiorno sarà calcolato e aggiunto 48h dopo il check-out.',
      points: 'punti',
    },

    loyaltyBenefits: {
      title: 'MaiStar Rewards Club vantaggi',
      list: '<li>Fino al 20% di sconto per prenotazioni effettuate tramite il centro prenotazioni Maistra o maistra.com</li><li>Colleziona i punti e ottieni premi esclusivi</li><li>Offerte esclusive “members-only” per i soli affiliati</li><li>Il tuo account - tutto in un unico posto</li>',
    },

    seeYouSoon: 'Ci vediamo presto!',
    unitOrRateMissing: "l'utente non ha selezionato unità o tariffa - andare al passaggio 1",
    personalDetailsMissing: "l'utente non ha inserito i dati personali - vai al passaggio 3",
    paymentDetailsMissing: "l'utente non ha inserito i dettagli di pagamento - vai al passaggio 4",
    propertyIdMissing: 'Almeno propertyId dovrebbe essere impostato',
    tooManyGuests: 'L’alloggio selezionato non è disponibile per il numero di persone richiesto. La preghiamo di riprovare con un altro alloggio.',
    notEnoughAdults: 'Probabilmente non abbastanza adulti per ospitare il numero di camere e le condizioni minime degli adulti. Seleziona un numero diverso di adulti e bambini.',
    notAcceptingChildren: "questa stanza non può accettare bambini, seleziona un'altra stanza",
    missingPhobsId: "probabilmente manca il phobsId in CMS.<br>Prova un'altra proprietà.",
    phobs: 'errore da PHOB: gli errori sono ancora da definire.',
  },
  nl: {
    cancel: 'Annuleren',
    back: 'Rug',
    confirm: 'Bevestigen',
    chooseAccomodation: 'Kies accommodatie',
    reservationDetails: 'Reserveringsdetails',
    personalDetails: 'Persoonlijke gegevens',
    finalConfirmation: 'Laatste bevestiging',
    thankYou: 'Bedankt voor uw reservering',

    fatalError: {
      title: 'Sorry, er is iets fout gegaan.',
      message: 'Ga alstublieft terug en probeer opnieuw.',
    },

    contactSlug: "contact",

    reservationSummary: {
      title: 'Overzicht boeking',
      guests: 'gasten',
      adults: '0 volwassenen | 1 volwassene | {n} volwassenen',
      children: '0 kinderen | 1 kind | {n} kinderen',
      nights: ' | 1 nacht | {n} nachten',
      selectRatePlan: 'Kies tariefplan',
      unitTotal: 'Eenheid totaal',
      total: 'Totaal',
      memberRate: 'Ledenprijs',
      regularRate: 'Normaal tarief',
      youSave: 'Als lid bespaar je',
      unit: 'Accommodatie',
      removeUnit: 'Verwijder accommodatie',
      lastAvailableUnit: 'U boekt de laatst beschikbare accommodatie.',
      guestSufficiency: 'Er zijn voldoende accommodaties toegevoegd, ga door. | Er moet <b>nog één gast worden toegevoegd</b> | Er moeten <b>{n} nog meer gasten worden toegevoegd</b>'
    },
    stepOne: {
      availableAccommodations: 'Beschikbare accommodaties',
      unavailableAccommodations: 'Accommodaties niet beschikbaar voor deze data',
      continue: 'Doorgaan',
      addUnitsToContinue: 'Voeg accommodaties toe om door te kunnen gaan',
      addUnitsForMoreGuests: '| Voeg eenheden toe voor <b>1 extra gast</b> | Voeg eenheden toe voor  <b>{n} meerdere gasten</b>',
      discoverMore: 'Ontdek meer',
      unitLimitReached: 'Online reserveren is beperkt tot 3 units. Neem contact met ons op voor grotere groepen',
      viewGallery: 'Galerij bekijken',
    },
    stepTwo: {
      selectedUnit: 'Geselecteerde eenheid',
      roomType: 'Kamertype',
      serviceType: 'Soort dienst',
      nights: 'Nachten',
      adults: 'volwassenen',
      children: 'Kinderen',
      services: 'Diensten',
      supplement: 'Toeslag',
      rate: 'Tarief',
      charge: 'Aanval',
      qty: 'AANTAL',
      unitTotal: 'Eenheid totaal',
      close: 'DICHTBIJ',
      updatePrice: 'Prijs bijwerken',
      continue: 'Doorgaan',
      vehicle: 'Voertuig',
      vehicleType: 'Type',
      vehicleTypes: {
        CAMPING_CAR: 'Auto en caravan',
        CARAVAN: 'Camper',
        TENT: 'Tent',
      },
      vehicleLength: 'Lengte',
    },
    stepThree: {
      reservationHolder: 'Reserveringshouder',
      title: 'Titel*',
      mr: 'Dhr',
      mrs: 'Mvr',
      name: 'Naam*',
      lastName: 'Achternaam*',
      email: 'E-mail*',
      verifyEmail: 'Verifieer Email*',
      country: 'Land*',
      phoneNumber: 'Telefoonnummer*',
      birthdate: 'Geboortedatum*',
      vehiclePlate: 'Voertuig registratie nummer',
      commentsRequests: 'Opmerkingen en verzoeken',
      subscribeNewsletter: 'Houdt me op de hoogte van nieuws, aanbiedingen en exclusieve ledenaanbiedingen.',
      confirmationSms: 'Bevestiging via sms verzenden',
      continue: 'Doorgaan',
      personalDetailsNote: 'Noch uw e-mailadres, noch andere informatie die u ons verstrekt, zal worden bekendgemaakt of ter beschikking worden gesteld aan derden. Lees ons {privacyPolicy} voor meer informatie over privacyprocedures.',
      privacyPolicy: 'Privacybeleid',
    },
    PerStay: 'Prijs voor het hele verblijf',
    PerNight: 'Prijs per nacht',
    PerPerson: 'Prijs per persoon',
    PerPersonNight: 'Prijs per persoon per nacht',
    PackageMin: 'Minimum prijs',
    ratePlan: {
      member: 'Lid',
      regular: 'Normaal',
      info: 'Meer informatie',
      minStay: 'Minimum verblijf',
      select: 'Kies ',
      selected: 'Geselecteerd',
      nights: ' nachten',
      rooms: 'eenheid',
      totalFor: 'Totaal voor',
      memberPriceInfo: '<p>Word eenvoudig lid van MaiStar Rewards Club tijdens het boeken en ontdek alle voordelen: </p><ul><li>Tot 20% korting bij reservering via het reserveringscentrum Maistra of maistra.com</li><li>Verzamel punten en wissel ze in voor exclusieve prijzen </li><li>Exclusieve aanbiedingen alleen voor leden </li><li>Uw online account - alles op één plek </li></ul><p>Lidmaatschap van het programma is gratis. </p>',
      ratePolicy: 'Tariefbeleid',
      salesPolicy: 'Algemene voorwaarden',
      add: 'Voeg toe',
    },
    serviceType: {
      BedOnly: 'Verhuur',
      BB: 'Logies met ontbijt',
      HB: 'Half pension',
      FB: 'Vol pension',
      AllInclusive: 'All inclusive',
      None: 'verhuur van kampeerplaatsen',
      undefined: 'verhuur van kampeerplaatsen'
    },
    chargeType: {
      PerNight: 'per dag',
      PerPerson: 'per persoon',
      PerPersonNight:	'per persoon/dag',
      PerStay: 'per dienst',
      PerUnit: 'per unit',
    },
    unitAvailable: {
      showRates: 'Toon tarieven',
      selectRate: 'Selecteer het tarief',
      hideRates: 'Tarieven verbergen',
      startingFrom: 'Beginnend vanaf',
      regular: 'Normaal',
      member: 'Lid',
      newPricingPolicy: 'Nieuw prijsbeleid',
      pricePerPitch: 'Prijs per staanplaats',
      upTo6People: 'maximaal 6 personen',
      upTo4People: 'maximaal 4 personen',
      upTo2People: 'maximaal 2 personen',
      sufficientUnitsAdded: 'Er zijn voldoende accommodaties toegevoegd. Als je nog een accommodatie wilt toevoegen, <a href=# class="add-guests">voeg dan meer gasten toe.</a>',
      minimumOccupancyRequired: 'Minimale bezetting is vereist. Als je deze accommodatie wilt toevoegen, <a href=# class="add-guests">voeg dan meer gasten toe.</a>',
      unavailableRatePlan: 'De accommodatie is niet beschikbaar binnen dit tarief.',
      noMoreUnits: 'Geen accommodaties meer beschikbaar.',
      nrUnitsAvailable: 'Geen accommodaties meer beschikbaar. | Nog maar 1 accommodatie beschikbaar. | Nog maar {n} accommodaties beschikbaar.',
      unitLimitReached: 'De limiet van het aantal accommodaties is bereikt. Meer accommodaties nodig? <a href="{url}">Neem contact met ons op</a> voor assistentie.',
    },
    unitDemand: {
      unavailable: 'Reserveringen voor daze units zijn op dit moment niet beschikbaar via online boeking.',
      contactText: 'Voor het maken van een reservering verzoeken wij u vriendelijk contact op te nemen met de afdeling Reservering.',
      contactButton: 'Neem contact met ons op',
      contactTextAdults: 'Reserveren voor dit aantal personen is alleen mogelijk via het reserveringscentrum.',
    },
    searching: 'Zoeken ...',
    stepFour: {
      NOGUARANTEE: 'Geen garantie nodig',
      BANKTRANSFER: 'Bankoverschrijving',
      CCARD_GUARANTEE: 'Creditcardgarantie',
      CCARD_PAYMENT: 'Creditcard betaling',
      INVOICE: 'Betaling: op rekening',
      VISA: 'Visa',
      VIELECTRON: 'Visa Electron',
      MASTERCARD: 'Master Card',
      AMEX: 'American Express',
      DINERS: 'Diners',
      reservationGuarantee: 'Reserveringsgarantie',
      guaranteeMethod: 'Garantie methode*',
      noGuarantee: 'Geen garantie nodig',
      additionalText: '',
      extraText: '',
      paymentCurrency: 'Betalingsvaluta',
      cardType: 'Cardtype*',
      cardNumber: 'Cardnummer*',
      expirationMonth: 'Vervalmaand*',
      expirationYear: 'Vervaljaar*',
      cvv: 'CVN/CVC*',
      firstName: 'Voornaam*',
      lastName: 'Achternaam*',
      fullAgreement: 'Ik heb de voorwaarden volledig gelezen en accepteer deze.*',
      fullAgreementPopup: 'Verkoopbeleid',
      confirmBooking: 'Bevestig Aankoop',
      faq: {
        holder: {
          cta: 'Reserveringshouder',
          title: 'Overzicht van de reservering',
          description: `Informatie reserveringshouder
          Om een reservering te kunnen bevestigen dient de klant (de reserveringshouder) enkele persoonlijke gegevens in te vullen alsook een garantie te geven voor de geplaatste reservering. De velden met (*) zijn verplichte velden.

            Aankomsttijd/vluchtinformatie
            Aankomsttijd/vluchtinformatie dient ingevuld te worden als u een transfer gereserveerd heeft. Vermeld duidelijk uw exacte aankomsttijd, exact vluchtnummer en de naam van uw luchtvaartmaatschappij. Als deze gegevens niet verstrekt worden kunnen wij de gevraagde service niet verlenen.`,
        },
        payment: {
          cta: 'Hoe te betalen',
          title: 'Betalingsmogelijkheden',
          description: `Online creditcard garantie
            De klant dient een geldig creditcardnummer in te voeren als garantie voor de gemaakte reservering.

            Garantie d.m.v. aanbetaling
            Indien de klant geen creditcard bezit kan men een aanbetaling doen via de bank (bankoverschrijving) direct op de bankrekening van Maistra Hospitality Group. Voor een aanbetaling via de bank dient u gebruikt te maken van het speciale aanbetalingsformulier. Druk het aanbetalingsformulier af, vul het volledig in en breng het naar uw bank. Het formulier bevat alle gegevens die uw bank nodig heeft om de aanbetaling uit te voeren.
            Het te betalen voorschot, percentages, voorwaarden enz. zijn duidelijk in de verkoopvoorwaarden van Maistra Hospitality Group vermeld.
            Voor bepaalde reserveringen /arrangementen/promotie aanbiedingen enz. is het doen van een aanbetaling (garantie voor reservering) verplicht.

            Het betalen van het voorschot (de garantie) kan op 3 verschillende manieren uitgevoerd worden: on-line door het invullen van het creditcard formulier in het reserveringsprogramma, off-line door het verzenden van een fax met behulp van het creditcard faxformulier of door een overschrijving te doen rechtstreeks naar de bankrekening van Maistra Hospitality Group.

            Indien een aanbetaling vereist is zal de camping bij reservering direct het bedrag van de creditcard afschrijven en de reservering bevestigen met een reserveringsnummer. Voor de regeling van het voorschot (garantie) volgt Maistra Hospitality Group de algemene voorwaarden.`,
        },
        immigration: {
          cta: 'Belangrijke immigratie-informatie',
          title: 'Belangrijke immigratieinformatie',
          description: `Inwoners van bepaalde landen zijn verplicht een bevestining te tonen van hun verblijfsadres in Kroatië om de grens te kunnen passeren.

          U kunt daarvoor de reserveringsbevestiging gebruiken. Deze wordt u toegezonden als uw reservering via het reserveringssysteem volledig bevestigd is.

          Print dit document en bewaar het bij uw reisdocumenten. (De reserveringsbevestiging kan afgedrukt worden in diverse talen, wij adviseren het document af te drukken in het Engels of in de taal van het te bezoeken land). U kunt op elk moment uw reservering opvragen en bekijken door in te loggen met uw e-mailadres en bevestigingscode.`,
        },
        confirmation: {
          cta: 'Reserveringsbevestiging',
          title: 'Reserveringsbevestiging',
          description: `Gelieve alle gegevens nauwkeurig te controleren
            Voordat u definitief gaat reserveren dient u alle ingebrachte gegevens nauwkeurig te controleren. Door op de 'Bewerken'-link te klikken kunt u de reservering wijzingen. Lees vooraf de annuleringsvoorwaarden van de verkoopovereenkomst.

            Reserveren
            Ga over tot definitieve reservering door 'Reserveren' aan te klikken. Een bevestigde reservering kan uitsluitend geannuleerd worden door toepassing van de annuleringsvoorwaarden hotel. De definitieve bevestiging wordt naar het e-mailadres verzonden dat u in het reserveringsformulier opgegeven heeft. Op de definitieve bevestiging, die u per e-mail ontvangt, zullen de annuleringsvoorwaarden duidelijk vermeld staan.`,
        },
      },
      cvvPopUp: {
        title: 'Wat is CVN / CVC?',
        description: 'Het kaartverificatienummer (CVN) of kaartverificatiecode (CVC) is een extra 3- of 4-cijferige code afgedrukt op uw creditcard.',
        paymentMethodsLeft: 'VISA, MASTERCARD & DINERS',
        paymentMethodsRight: 'AMERICAN EXPRESS',
        back: 'Achterkant van de kaart',
        backDescription: 'Drie cijfers rechts van de kenmerkende strip.',
        front: 'Voorkant van de kaart',
        frontDescription: 'Vier cijfers gelegen aan de linkerkant of de rechterkant.',
      },
    },
    mustAgree: 'U moet akkoord gaan',
    bothRequired: 'Het veld Maand en Jaar zijn beiden verplicht.',
    mustBeInFuture: 'Vervaldatum (maand/jaar) moet in de toekomst liggen',
    stepFive: {
      reservationCode: 'Uw reserveringscode is',
      requestReceived: 'Uw boekingsaanvraag is ontvangen.',
      codeSent: 'Reserveringscode is verzonden naar',
      reservationHolder: 'Reserveringshouder',
      firstName: 'Voornaam',
      lastName: 'Achternaam',
      email: 'E-mail',
      phoneNumber: 'Telefoonnummer',
      guarantee: 'Garantie',
      creditType: 'Credit card type',
      expirationDate: 'vervaldatum',
      nameCard: 'naam op kaart',
      noGuarantee: 'Geen garantie nodig',
      cardNumber: 'Kaartnummer',
      cardType: 'Kaarttype',
      printPage: 'Print deze pagina',
      bookAnother: 'Boek een andere',
      cancelReservation: 'Annuleer reservering',
      mistakeVisits: 'U bent per ongeluk op deze pagina gekomen',
      checkReservation: 'Als u eerder een kamer heeft geboekt, controleer dan de e-mail voor details.',
    },

    linkBanner: {
      unlockBenefits: 'Ontgrendel exclusieve voordelen met ons loyaliteitsprogramma',
      youEarn: 'Met dit verblijf verdient u',
      clickLink: 'Klik op de link die naar <strong>{0}</strong> is verzonden om uw loyaliteitsprofiel te activeren en te profiteren van speciale voordelen.<br> Heeft u geen e-mail ontvangen? <a href="https://www.maistra.com/nl/loyalty/activation/">Activeringslink aanvragen.</a>',
      timeDisclaimer: 'Het totale aantal punten gebaseerd op uw gehele verblijf wordt 48 uur na het uitchecken berekend en toegevoegd.',
      points: 'punten',
    },

    loyaltyBenefits: {
      title: 'MaiStar Rewards Club voordelen',
      list: '<li>Tot 20% korting bij reservering via het reserveringscentrum Maistra of maistra.com</li><li>Verzamel punten en pak exclusieve prijzen</li><li>Exclusieve "Alleen voor leden" aanbiedingen</li><li>Uw account - alles op één plek</li>',
    },

    seeYouSoon: 'Tot ziens!',
    unitOrRateMissing: 'gebruiker heeft geen eenheid of tarief geselecteerd - ga naar stap 1',
    personalDetailsMissing: 'gebruiker heeft geen persoonlijke gegevens ingevoerd - ga naar stap 3',
    paymentDetailsMissing: 'gebruiker heeft geen betalingsgegevens ingevoerd - ga naar stap 4',
    propertyIdMissing: 'PropertyId moet in ieder geval zijn ingesteld',
    tooManyGuests: 'Deze unit is niet beschikbaar voor het gevraagde aantal personen. Probeer het opnieuw met een andere unit.',
    notEnoughAdults: 'Waarschijnlijk niet genoeg volwassenen om het aantal kamers en minAdults te huisvesten. Selecteer een ander aantal volwassenen en kinderen.',
    notAcceptingChildren: 'deze kamer accepteert geen kinderen, selecteer een andere kamer',
    missingPhobsId: 'waarschijnlijk ontbreekt phobsId in CMS.<br>Probeer een andere eigenschap.',
    phobs: 'fout van PHOBS: fouten moeten nog worden gedefinieerd.',
  },
  pl: {
    cancel: 'Skasuj',
    back: 'Wróć',
    confirm: 'Potwierdź',

    fatalError: {
      title: 'Przepraszamy, ale coś poszło nie tak.',
      message: 'Proszę wrócić i spróbować ponownie.',
    },

    contactSlug: "contact",

    // menu
    chooseAccomodation:  'Wybierz zakwaterowanie',
    reservationDetails: 'Szczegóły rezerwacji',
    personalDetails: 'Dane osobowe',
    finalConfirmation: 'Ostateczne potwierdzenie',
    thankYou: 'Dziękujemy za dokonanie rezerwacji',

    reservationSummary: {
      title: 'Cała rezerwacja',
      guests: 'goście',
      adults: '0 dorosłych | 1 dorosły | {n} dorosłych',
      children: '0 dzieci | 1 dziecko | {n} dzieci',
      nights: ' | 1 noc | {n} noce/nocy',
      selectRatePlan: 'Wybierz plan cenowy',
      unitTotal: 'Suma jednostek',
      total: 'Suma',
      memberRate: 'Stawka członkowska',
      regularRate: 'Stawka regularna',
      youSave: 'Jako członek oszczędzasz',
      unit: 'Jednostka',
      removeUnit: 'Usuń jednostkę',
      lastAvailableUnit: 'Rezerwujesz ostatnią dostępną jednostkę zakwaterowania.',
      guestSufficiency: 'Dodano wystarczającą liczbę jednostek, kontynuuj. | Należy dodać <b>jeszcze jednego gościa</b> | Należy dodać <b>{n} większą liczbę gości</b>'
    },

    stepOne: {
      availableAccommodations: 'Dostępne zakwaterowanie',
      unavailableAccommodations : 'Zakwaterowanie niedostępne w wybranych terminach',
      continue: 'Kontyntynuj',
      addUnitsToContinue: 'Dodaj jednostki, aby kontynuować',
      addUnitsForMoreGuests: '| Dodaj nocleg dla <b>1 gościa więcej</b> | Dodaj nocleg dla <b>{n} więcej liczby gości</b>',
      discoverMore: 'Dowiedz się więcej',
      unitLimitReached: 'Rezerwacja online jest ograniczona do 3 jednostek. Skontaktuj się z nami w przypadku większych grup',
      viewGallery: 'Zobacz galerię',
    },

    stepTwo: {
      selectedUnit: 'Wybrana jednostka',
      roomType: 'Rodzaj pokoju',
      serviceType: 'Typ usługi',
      nights: 'Noce',
      adults: 'Dorośli',
      children: 'Dzieci',
      services: 'Usługi',
      supplement: 'Dodatek',
      rate: 'Stawka',
      charge: 'Opłata',
      qty: 'ILOŚĆ',
      unitTotal: 'Suma jednostek',
      close: 'ZAMKNIJ',
      updatePrice: 'Zaktualizuj cenę',
      continue: 'Kontyntynuj',
      vehicle: 'Pojazd',
      vehicleType: 'Typ',
      vehicleTypes: {
        CAMPING_CAR: 'Samochód z przyczepą kempingową',
        CARAVAN: 'Kamper',
        TENT: 'Namiot',
      },
      vehicleLength: 'Długość',
    },

    stepThree: {
      reservationHolder: 'Właściciel rezerwacji',
      title: 'Tytuł*',
      mr: 'Pan',
      mrs: 'Pani',
      name: 'Imię*',
      lastName: 'Nazwisko*',
      email: 'Email*',
      verifyEmail: 'Potwierdź email*',
      country: 'Kraj*',
      phoneNumber: 'Numer telefonu*',
      birthdate: 'Data urodzin*',
      vehiclePlate: 'Numer rejestracyjny pojazdu',
      commentsRequests: 'Uwagi i pytania',
      subscribeNewsletter: 'Informuj mnie na bieżąco o nowościach, ofertach i ekskluzywnych ofertach członkowskich',
      confirmationSms: 'Wyślij potwierdzenie SMS-em',
      continue: 'Kontyntynuj',
      personalDetailsNote: 'Ani Twój adres e-mail, ani inne informacje, które nam przekazujesz, nie zostaną ujawnione ani udostępnione osobom trzecim. Przeczytaj naszą {privacyPolicy}, aby dowiedzieć się więcej o procedurach prywatności.',
      privacyPolicy: 'Polityka Prywatności',
    },

    PerStay: 'Cena za cały pobyt',
    PerNight: 'Cena za noc',
    PerPerson: 'Cena za osobę',
    PerPersonNight: 'Cena za osobę za noc',
    PackageMin: 'Cena minimalna',

    ratePlan: {
      member: 'Członek',
      regular: 'Regularny',
      info: 'Więcej informacji',
      minStay: 'Minimalny pobyt',
      select: 'Wybierz ',
      selected: 'Wybrano',
      nights: ' noce',
      rooms: 'jednostka',
      totalFor: 'Łącznie za',
      memberPriceInfo: '<p>Dołącz do MaiStar Rewards Club podczas dokonywania rezerwacji i odkryj wszystkie korzyści: </p><ul><li>Do 20% zniżki na rezerwacje przez w centrum rezerwacji Maistra lub na maistra.com</li><li>zbieraj punkty i wygrywaj ekskluzywne nagrody </li><li>ekskluzywne oferty tylko dla członków </li><li>twoje konto internetowe – wszystko w jednym miejscu </li></ul><p>Członkostwo w programie jest bezpłatne. </p>',
      ratePolicy: 'Warunki ogólne',
      salesPolicy: 'Algemene voorwaarden',
      add: 'Dodaj',
    },

    serviceType: {
      BedOnly: 'Wynajem',
      BB: 'Nocleg ze śniadaniem',
      HB: 'Śniadania i obiadokolacje',
      FB: 'Pełne wyżywienie',
      AllInclusive: 'All inclusive',
      None: 'wynajęcie parceli',
      undefined: 'wynajęcie parceli'
    },

    chargeType: {
      PerNight: 'za dzień',
      PerPerson: 'za osobę',
      PerPersonNight:	'za osobę/dzień',
      PerStay: 'za usługę',
      PerUnit: 'za jednostkę',
    },

    unitAvailable: {
      showRates: 'Pokaż stawki',
      selectRate: 'Wybierz stawkę',
      hideRates: 'Ukryj stawki',
      startingFrom: 'Zaczynając od',
      regular: 'Regularny',
      member: 'Członek',
      newPricingPolicy: 'Nowa polityka cenowa',
      pricePerPitch: 'Cena za stanowisko',
      upTo6People: 'do 6 osób',
      upTo4People: 'do 4 osób',
      upTo2People: 'do 2 osób',
      sufficientUnitsAdded: 'Dodano wystarczającą liczbę jednostek. Aby dodać kolejną jednostkę, <a href=# class="add-guests">dodaj większą liczbę gości.</a>',
      minimumOccupancyRequired: 'Wymagane jest minimalne obłożenie. Aby dodać tę jednostkę, <a href=# class="add-guests">dodaj większą liczbę gości.</a>',
      unavailableRatePlan: 'Jednostka niedostępne dla tego planu stawki.',
      noMoreUnits: 'Nie są dostępne dalsze jednostki.',
      nrUnitsAvailable: 'Brak wolnych jednostek. | Pozostała tylko 1 wolna jednostka. | Pozostały tylko {n} wolne jednostki.',
      unitLimitReached: 'Osiągnięto limit jednostek. Potrzebujesz więcej jednostek? <a href="{url}">Skontaktuj się z nami</a>, aby uzyskać pomoc.',
    },

    unitDemand: {
      unavailable: 'Rezerwacje dla wybranej jednostki w tej chwili nie są dostępne poprzez rezerwację online.',
      contactText: 'W celu dokonania rezerwacji prosimy o kontakt z Działem Rezerwacji.',
      contactButton: 'Skontaktuj się z nami',
      contactTextAdults: 'Rezerwacja z wybraną liczbą osób jest możliwa tylko za pośrednictwem centrum rezerwacji.',
    },

    // załaduj
    searching: 'Wyszukiwanie...',

    stepFour: {
      NOGUARANTEE: 'Gwarancja nie jest wymagana',
      BANKTRANSFER: 'Wpłata przelewem bankowym',
      CCARD_GUARANTEE: 'Gwarancja kartą kredytową',
      CCARD_PAYMENT: 'Płatność kartą kredytową',
      INVOICE: 'Sposób płatności: na podstawie rachunku',
      VISA: 'Visa',
      VIELECTRON: 'Visa Electron',
      MASTERCARD: 'Master Card',
      AMEX: 'American Express',
      DINERS: 'Diners',
      reservationGuarantee: 'Gwarancja rezerwacji',
      guaranteeMethod: 'Sposób gwarancji*',
      noGuarantee: 'Gwarancja nie jest wymagana',
      additionalText: '',
      extraText: '',
      paymentCurrency: 'Waluta płatności',
      cardType: 'Typ karty*',
      cardNumber: 'Numer karty*',
      expirationMonth: 'Miesiąc ważności*',
      expirationYear: 'Rok ważności*',
      cvv: 'CVN/CVC*',
      firstName: 'Imię*',
      lastName: 'Nazwisko*',
      fullAgreement: 'Przeczytałem i akceptuję Regulamin.*',
      fullAgreementPopup: 'Polityka sprzedaży',
      confirmBooking: 'Potwierdź Zakup',
      faq: {
        holder: {
          cta: 'Właściciel rezerwacji',
          title: 'Właściciel rezerwacji',
          description: `Dane właściciela rezerwacji',
            In aby rezerwacja mogła zostać zrealizowana, klient jest zobowiązany do wprowadzenia danych osobowych oraz ewentualnie numeru karty kredytowej, jeśli nie wybrał innego sposobu zabezpieczenia rezerwacji.

            Arrival informacje/Szczegóły lotu
            If rezerwacja transferu w dniu przyjazdu, prosimy o podanie informacji o przylocie, w tym godziny przylotu, numeru lotu i przewoźnika. W przeciwnym razie ta usługa nie będzie świadczona.`,
        },
        payment: {
          cta: 'Jak zapłacić',
          title: 'Jak zapłacić?',
          description: `Zabezpieczenie kartą kredytową - online
            The Klient proszony jest o podanie ważnego numeru karty kredytowej jako gwarancji rezerwacji.

            Guarantee przedpłata
            If Klient nie posiada karty kredytowej, może wpłacić kaucję gwarancyjną za pośrednictwem swojego banku bezpośrednio na rachunek Maistra d.d. . W celu dokonania takiej przedpłaty prosimy o wydrukowanie i wypełnienie dostarczonego Formularza płatności. Ten formularz zawiera pełne informacje wymagane przez Twój bank w celu pomyślnego przekazania kwoty przedpłaty.

            The termin i warunki takiej gwarancji są określone w Polityce Serwisu i Sprzedaży Maistra Hospitality Group.

            Prepayment
            A W przypadku niektórych rezerwacji, pakietów specjalnych lub stawek promocyjnych, może być wymagana przedpłata częściowa lub pełna. Płatności takiej można dokonać online, wypełniając Formularz Karty Kredytowej, offline - wysyłając Formularz Karty Kredytowej faksem lub przelewem bezpośrednio na konto Maistra Hospitality Group.

            In w przypadku żądania przedpłaty, obiekt obciąży kartę kredytową klienta po potwierdzeniu rezerwacji i wydaniu numeru potwierdzenia. Maistra Hospitality Group będzie obsługiwała takie płatności zgodnie z Warunkami świadczenia usług i Polityką sprzedaży.`,
        },
        immigration: {
          cta: 'Ważne informacje imigracyjne',
          title: 'Ważne informacje imigracyjne',
          description: `Obywatele niektórych krajów, którzy są zobowiązani do przedstawienia dowodu zabezpieczenia zakwaterowania, podczas przekraczania granicy.

            You mogą w tym celu użyć formularza Potwierdzenia Rezerwacji. Ten formularz jest wysyłany do Ciebie po pomyślnej rezerwacji zakwaterowania za pośrednictwem naszego systemu rezerwacji i zawiera kodu rezerwacji. Możesz przejrzeć lub ponownie wydrukować Potwierdzenie rezerwacji w dowolnym momencie, logując się do naszego systemu rezerwacji.

            Even choć dostępny w kilku językach, dla uproszczenia prosimy o wydrukowanie formularza w języku kraju docelowego lub w języku angielskim.`,
        },
        confirmation: {
          cta: 'Potwierdzenie rezerwacji',
          title: 'Potwierdzenie rezerwacji',
          description: `Proszę zweryfikować swoje dane
            Please sprawdź i zweryfikuj wszystkie dane przed przesłaniem. Jeśli chcesz zmodyfikować szczegóły swojej rezerwacji, kliknij „Edytuj”. Aby zrozumieć zasady anulowania i ewentualne kary za anulowanie, zapoznaj się z Warunkami świadczenia usług i Polityką sprzedaży.

            Confirm rezerwacja
            Click 'Potwierdź rezerwację', aby potwierdzić i aktywować rezerwację. W ten sposób potwierdzoną rezerwację można anulować lub zmienić zgodnie z warunkami Polityki Sprzedaży hotelu. Potwierdzenie rezerwacji wraz z kodem zostanie wysłane na Twój adres e-mail.`,
        },
      },
      cvvPopUp: {
        title: 'Co to jest cvn/cvc?',
        description: 'Numer weryfikacji karty (CVN) lub kod weryfikacyjny karty (CVC) jest dodatkowym kodem 3 lub 4 cyfrowym wydrukowanym na karcie kredytowej.',
        paymentMethodsLeft: 'VISA, MASTERCARD & DINERS',
        paymentMethodsRight: 'AMERICAN EXPRESS',
        back: 'Tylna strona karty',
        backDescription: 'Trzy cyfry znajdujące się po prawej stronie paska podpisu.',
        front: 'Przednia strona karty',
        frontDescription: 'Cztery cyfry znajdujące się po lewej lub prawej stronie.',
      },
    },

    mustAgree: 'Musisz się zgodzić',
    bothRequired: 'Pola miesiąca i roku są wymagane.',
    mustBeInFuture: 'Data ważności (miesiąc/rok) musi przypadać w przyszłości',

    stepFive: {
      reservationCode: 'Twój kod rezerwacji to',
      requestReceived: 'Otrzymaliśmy Twoją prośbę o rezerwację.',
      codeSent: 'Kod rezerwacji został wysłany do',
      reservationHolder: 'Właściciel rezerwacji',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      email: 'Email',
      phoneNumber: 'Numer telefonu',
      guarantee: 'Gwarancja',
      creditType: 'Typ karty kredytowej',
      expirationDate: 'Termin ważności',
      nameCard: 'Nazwisko na karcie',
      noGuarantee: 'Gwarancja nie jest wymagana',
      cardNumber: 'Numer karty',
      cardType: 'Typ karty',
      printPage: 'Wydrukuj tę stronę',
      bookAnother: 'Zarezerwuj kolejny',
      cancelReservation: 'Anuluj rezewację',
      mistakeVisits: 'Trafiłeś na tę stronę przez pomyłkę',
      checkReservation: 'Jeśli zarezerwowałeś pokój wcześniej, sprawdź e-mail, aby uzyskać szczegółowe informacje.',
    },

    linkBanner: {
      unlockBenefits: 'Odblokuj wyjątkowe korzyści z naszym programem lojalnościowym',
      youEarn: 'Za ten pobyt otrzymasz',
      clickLink: 'Kliknij w link wysłany na adres <strong>{0}</strong>, by aktywować swój profil lojalnościowy i zacznij cieszyć się specjalnymi korzyściami.<br> Nie otrzymałeś wiadomości e-mail? <a href="https://www.maistra.com/pl/loyalty/activation/">Wyślij prośbę o link aktywacyjny.</a>',
      timeDisclaimer: 'Łączna liczba punktów, wynikająca z Twojego całego pobytu, zostanie obliczona i dodana w 48 godzin po wymeldowaniu.',
      points: 'punktów',
    },

    loyaltyBenefits: {
      title: 'MaiStar Rewards Club bonusy',
      list: '<li>Do 20% zniżki na rezerwacje przez w centrum rezerwacji Maistra lub na maistra.com</li><li>Zbieraj punkty i zdobywaj wyjątkowe nagrody</li><li>Wyjątkowe oferty „tylko dla członków”</li><li>Twoje konto – wszystko w jednym miejscu</li>',
    },

    seeYouSoon: 'Do zobaczenia wkrótce!',

    // TODO błędy
    unitOrRateMissing: 'użytkownik nie wybrał jednostki lub stawki - przejdź do kroku 1',
    personalDetailsMissing: 'użytkownik nie wprowadził danych osobowych - przejdź do kroku 3',
    paymentDetailsMissing: 'użytkownik nie wprowadził szczegółów płatności - przejdź do kroku 4',
    propertyIdMissing: 'Co najmniej identyfikator obiektu powinien być ustawiony',
    tooManyGuests: 'Wybrana jednostka nie jest dostępna dla żądanej liczby osób. Spróbuj ponownie, wybierając inną jednostkę.',
    notEnoughAdults: `prawdopodobnie nie ma wystarczającej liczby dorosłych, aby pomieścić liczbę pokoi i minimalną liczbę dorosłych.
                      select różna liczba dorosłych i dzieci`,
    notAcceptingChildren: 'ten pokój nie może przyjąć dzieci, wybierz inny pokój',
    missingPhobsId: 'prawdopodobnie brakuje identyfikatora phobsId w CMS.<br>Wypróbuj inną usługę.',
    phobs: 'błąd z PHOBS: błędy są jeszcze do zdefiniowania.',
  },
  si: {
    cancel: 'Prekliči',
    back: 'Nazaj',
    confirm: 'Potrdi',

    fatalError: {
      title: 'Žal nam je, ampak prišlo je do napake.',
      message: 'Prosimo, pojdite nazaj in poskusite znova.',
    },

    contactSlug: "kontakt",

    // meni
    chooseAccomodation:  'Izberite nastanitev',
    reservationDetails: 'Podrobnosti o rezervaciji',
    personalDetails: 'Osebni podatki',
    finalConfirmation: 'Končna potrditev',
    thankYou: 'Hvala za vašo rezervacijo',

    reservationSummary: {
      title: 'Povzetek rezervacije',
      guests: 'gostje',
      adults: '0 odraslih | 1 odrasel | 2 odrasla | 3 odrasli | 4 odrasli | {n} odraslih',
      children: '0 otrok | 1 otrok | 2 otroka | 3 otroci | 4 otroci | {n} otrok',
      nights: ' | 1 noč | {n} noči',
      selectRatePlan: 'Izberite kategorijo cene',
      unitTotal: 'Skupaj enot',
      total: 'Skupaj',
      memberRate: 'Cena za članove',
      regularRate: 'Redna cena',
      youSave: 'Prihrani kot član',
      unit: 'Enota',
      removeUnit: 'Odstrani enoto',
      lastAvailableUnit: 'Rezervirali boste zadnjo razpoložljivo enoto.',
      guestSufficiency: 'Dodanih je dovolj enot. Nadaljujte. | Dodati morate <b>še enega gosta.</b> | Dodati morate <b>še {n} gostov.</b>'
    },

    stepOne: {
      availableAccommodations: 'Dostopne nastanitve',
      unavailableAccommodations : 'Nastanitve niso na voljo za izbrane datume',
      continue: 'Naprej',
      addUnitsToContinue: 'Dodaj enote za nadaljevanje',
      addUnitsForMoreGuests: '| Dodaj enote za <b>1 dodatnega gosta</b> | Dodaj enote za toliko dodatnih gostov: <b>{n} </b>',
      discoverMore: 'Preberite več',
      unitLimitReached: 'Spletna rezervacija je omejena na tri enote. Za večje skupine se obrnite na nas',
      viewGallery: 'Ogled galerije',
    },

    stepTwo: {
      selectedUnit: 'Izbrana enota',
      roomType: 'Vrsta sobe',
      serviceType: 'Vrsta storitve',
      nights: 'Nočitve',
      adults: 'Odrasli',
      children: 'Otroci',
      services: 'Storitve',
      supplement: 'Doplačilo',
      rate: 'Cena',
      charge: 'Plačilo',
      qty: 'KOLIČINA',
      unitTotal: 'Skupaj enot',
      close: 'ZAPRI',
      updatePrice: 'Posodobi ceno',
      continue: 'Naprej',
      vehicle: 'Vozilo',
      vehicleType: 'Tip',
      vehicleTypes: {
        CAMPING_CAR: 'Avtomobil in kamp prikolica',
        CARAVAN: 'Avtodom',
        TENT: 'Šotor',
      },
      vehicleLength: 'Dolžina',
    },

    stepThree: {
      reservationHolder: 'Nosilec rezervacije',
      title: 'Naziv*',
      mr: 'G.',
      mrs: 'Ga.',
      name: 'Ime*',
      lastName: 'Priimek*',
      email: 'E-poštni naslov*',
      verifyEmail: 'Potrdite e-poštni naslov*',
      country: 'Država*',
      phoneNumber: 'Telefonska številka*',
      birthdate: 'Datum rojstva*',
      vehiclePlate: 'Registrska številka vozila',
      commentsRequests: 'Komentarji in zahteve',
      subscribeNewsletter: 'Obveščajte me o novicah, ponudbah in ekskluzivnih ponudbah za člane',
      confirmationSms: 'Pošlji potrditev po SMS-sporočilu',
      continue: 'Naprej',
      personalDetailsNote: 'Ne vaš e-poštni naslov ne vaše druge informacije, ki nam jih posredujete, ne bodo razkrite ali posredovane kateri koli tretji strani. Preberite naš {privacyPolicy}, kjer najdete več informacij o postopkih v zvezi z zasebnostjo.',
      privacyPolicy: 'Pravilnik o varovanju zasebnosti',
    },

    PerStay: 'Cena za celotno bivanje',
    PerNight: 'Cena na noč',
    PerPerson: 'Cena na osebo',
    PerPersonNight: 'Cena na osebo na noč',
    PackageMin: 'Najnižja cena',

    ratePlan: {
      member: 'Član',
      regular: 'Redna',
      info: 'Več informacij',
      minStay: 'Najkrajše bivanje',
      select: 'Izberi ',
      selected: 'Izbrano',
      nights: ' nočitvi',
      rooms: 'enota',
      totalFor: 'Skupaj za',
      memberPriceInfo: '<p>Med rezervacijo se enostavno pridružite MaiStar Rewards Clubu in odkrijte vse ugodnosti: </p><ul><li>Do 20 % popust na rezervacije prek Maistrinega centra za rezervacije ali na strani maistra.com</li><li>Zbirajte točke in unovčite ekskluzivne nagrade </li><li>Ekskluzivne ponudbe samo za člane </li><li>Vaš spletni račun – vse na enem mestu </li></ul><p>Članstvo v programu je brezplačno. </p>',
      ratePolicy: 'Pravilnik o cenah',
      salesPolicy: 'Splošni pogoji poslovanja',
      add: 'Dodaj',
    },

    serviceType: {
      BedOnly: 'najem',
      BB: 'Nočitev z zajtrkom',
      HB: 'Polpenzion',
      FB: 'Polni penzion',
      AllInclusive: 'All inclusive',
      None: 'najem parcele',
      undefined: 'najem parcele'
    },

    chargeType: {
      PerNight: 'na dan',
      PerPerson: 'na osebo',
      PerPersonNight:	'na osebo/dan',
      PerStay: 'na storitev',
      PerUnit: 'na enoto',
    },

    unitAvailable: {
      showRates: 'Pokaži cene',
      selectRate: 'Izberi ceno',
      hideRates: 'Skrij cene',
      startingFrom: 'Od',
      regular: 'Redna',
      member: 'Član',
      newPricingPolicy: 'Nov pravilnik o cenah',
      pricePerPitch: 'Cena na parcelo',
      upTo6People: 'do 6 oseb',
      upTo4People: 'do 4 oseb',
      upTo2People: 'do 2 osebi',
      sufficientUnitsAdded: 'Dodanih je dovolj enot. Če želite dodati še eno enoto, <a href=# class="add-guests">dodajte več gostov.</a>',
      minimumOccupancyRequired: 'Zahtevana je minimalna zasedenost. Če želite dodati to enoto, <a href=# class="add-guests">dodajte več gostov.</a>',
      unavailableRatePlan: 'Enota ni na voljo za izbran cenik.',
      noMoreUnits: 'Enote niso več na voljo.',
      nrUnitsAvailable: 'Na voljo ni nobene enote. | Na voljo je le še {n} enota. | Na voljo sta le še {n} enoti. | Na voljo so le še {n} enote. | Na voljo je le še {n} enot.',
      unitLimitReached: 'Dosežena je omejitev enote. Potrebujete več enot? <a href="{url}">Stopite v stik z nami</a> za pomoč.',
    },

    unitDemand: {
      unavailable: 'Spletne rezervacije za izbrano enoto trenutno niso na voljo.',
      contactText: 'Za izvedbo rezervacije se obrnite na rezervacijski oddelek.',
      contactButton: 'Obrnite se na nas',
      contactTextAdults: 'Rezervacija z izbranim številom oseb je možna samo preko rezervacijskega centra.',
    },

    // nalaganje
    searching: 'Iskanje ...',

    stepFour: {
      NOGUARANTEE: 'Garancija ni potrebna',
      BANKTRANSFER: 'Bančno nakazilo',
      CCARD_GUARANTEE: 'Garancija prek kreditne kartice',
      CCARD_PAYMENT: 'Plačilo s kreditno kartico',
      INVOICE: 'Način plačila: prek računa',
      VISA: 'Visa',
      VIELECTRON: 'Visa Electron',
      MASTERCARD: 'Master Card',
      AMEX: 'American Express',
      DINERS: 'Diners',
      reservationGuarantee: 'Garancija rezervacije',
      guaranteeMethod: 'Način garancije*',
      noGuarantee: 'Garancija ni potrebna',
      additionalText: '',
      extraText: '',
      paymentCurrency: 'Valuta plačila',
      cardType: 'Vrsta kartice*',
      cardNumber: 'Številka kartice*',
      expirationMonth: 'Mesec poteka*',
      expirationYear: 'Leto poteka*',
      cvv: 'CVN/CVC*',
      firstName: 'Ime*',
      lastName: 'Priimek*',
      fullAgreement: 'Vse pogoje sem prebral/-a in jih sprejemam.*',
      fullAgreementPopup: 'Pravilnik o prodaji',
      confirmBooking: 'Potrditev Nakupa',
      faq: {
        holder: {
          cta: 'Nosilec rezervacije',
          title: 'Nosilec rezervacije',
          description: `Informacije o nosilcu rezervacije
            In Za dokončanje rezervacije mora stranka vpisati osebne podatke in številko kreditne kartice, razen če je uporabljena kakšna druga garancija rezervacije. (*) označuje obvezna polja.

            Arrival Informacije/podrobnosti o letu
            If Če boste rezervirali prevoz ob prihodu, vpišite informacije o prihodu, vključno z uro prihoda, številko leta in letalsko družbo. V nasprotnem primeru storitev ne bo rezervirana.`,
        },
        payment: {
          cta: 'Kako plačati',
          title: 'Kako plačati?',
          description: `Garancija prek kreditne kartice - prek spleta
            The Stranka mora navesti številko veljavne kreditne kartice, s katero jamči za svojo rezervacijo.

            Guarantee Predplačilo
            If Če stranka nima kreditne kartice, lahko za garancijo uporabi predplačilo, ki ga izvede z bančnim nakazilom neposredno na račun družbe Maistra Hospitality Group. Za izvedbo takšnega predplačila natisnite in izpolnite priloženi obrazec za plačilo. Na tem obrazcu so navedene vse informacije, ki jih zahteva vaša banka za uspešno posredovanje zneska predplačila.

            The Rok in pogoji takšne garancije so opredeljeni v pravilniku o storitvah in prodaji družbe Maistra Hospitality Group.

            Prepayment
            A Delno ali polno predplačilo bo morda zahtevano pri določenih rezervacijah, posebnih paketih ali promocijskih ponudbah. Takšna plačila je mogoče izvesti z izpolnitvijo obrazca za plačilo s kreditno kartico, fizično, s pošiljanjem obrazca za plačilo s kreditno kartico po faksu, ali z bančnim nakazilom neposredno na račun družbe Maistra Hospitality Group.

            In V primeru zahteve za predplačilo bo objekt bremenil strankino kreditno kartico po potrditvi garancije in izdaji rezervacijske številke. Družba Maistra Hospitality Group bo takšna plačila obravnavala v skladu s pogoji pravilnika o storitvah in prodaji.`,
        },
        immigration: {
          cta: 'Pomembne informacije o vstopu v državo',
          title: 'Pomembne informacije o vstopu v državo',
          description: `Državljani nekaterih držav morajo pri prečkanju meje predložiti potrdilo o dogovorjeni nastanitvi.

            You V te namene lahko uporabite obrazec s potrdilom o rezervaciji. Ta obrazec vam pošljemo po uspešni rezervaciji nastanitve prek našega rezervacijskega sistema in pridobitvi rezervacijske kode. Potrdilo o rezervaciji si lahko ogledate ali znova natisnete kadar koli z vpisom v naš rezervacijski sistem.

            Even Čeprav je na voljo v več jezikih, vam zaradi priročnosti svetujemo, da obrazec natisnete v jeziku ciljne države ali angleščini.`,
        },
        confirmation: {
          cta: 'Potrdilo o rezervaciji',
          title: 'Potrdilo o rezervaciji',
          description: `Preverite svoje podatke
            Please Pred pošiljanjem preverite in potrdite vse podatke. Če želite podatke svoje rezervacije spremeniti, kliknite 'Uredi'. Za lažje razumevanje pravilnika o odpovedih in morebitnih stroških odpovedi preberite pravilnik o storitvah in prodaji.

            Confirm Potrditev rezervacije
            Click Kliknite 'Potrditev rezervacija', da potrdite in aktivirate svojo rezervacijo. Tudi ko je rezervacija potrjena, jo je mogoče v skladu s pogoji prodaje hotela preklicati ali spremeniti. Potrdilo o rezervaciji in koda bosta poslana na vaš e-poštni naslov.`,
        },
      },
      cvvPopUp: {
        title: 'Kaj je CVN/CVC?',
        description: 'Številka za preverjanje kartice (CVN) ali koda za preverjanje kartice (CVC) je dodatna tri- ali štirimestna koda, natisnjena na vaši kreditni kartici.',
        paymentMethodsLeft: 'VISA, MASTERCARD IN DINERS',
        paymentMethodsRight: 'AMERICAN EXPRESS',
        back: 'Hrbtna stran kartice',
        backDescription: 'Tri števke na desni strani podpisnega traku.',
        front: 'Sprednja stran kartice',
        frontDescription: 'Štiri števke na levi ali desni strani.',
      },
    },

    mustAgree: 'Morate sprejeti',
    bothRequired: 'Obvezna sta tako leto kot mesec.',
    mustBeInFuture: 'Datum poteka (mesec/leto) mora biti v prihodnosti',

    stepFive: {
      reservationCode: 'Koda vaše rezervacije je',
      requestReceived: 'Vašo prošnjo za rezervacijo smo prejeli.',
      codeSent: 'Koda za rezervacijo je bila poslana',
      reservationHolder: 'Nosilec rezervacije',
      firstName: 'Ime',
      lastName: 'Priimek',
      email: 'E-poštni naslov',
      phoneNumber: 'Telefonska številka',
      guarantee: 'Garancija',
      creditType: 'Vrsta kreditne kartice',
      expirationDate: 'Datum poteka',
      nameCard: 'Ime na kartici,',
      noGuarantee: 'Garancija ni potrebna',
      cardNumber: 'Številka kartice',
      cardType: 'Vrsta kartice',
      printPage: 'Natisni to stran',
      bookAnother: 'Rezervirajte drugo nastanitev',
      cancelReservation: 'Prekliči rezervacijo',
      mistakeVisits: 'Na to stran ste prišli pomotoma',
      checkReservation: 'Če ste sobo že rezervirali, preverite podrobnosti v e-sporočilu',
    },

    linkBanner: {
      unlockBenefits: 'Odklenite ekskluzivne ugodnosti z našim programom zvestobe',
      youEarn: 'S tem bivanjem boste osvojili',
      clickLink: 'Kliknite povezavo, ki smo jo poslali na naslov <strong>{0}</strong>, in aktivirajte svoj profil zvestobe ter začnite uživati v posebnih ugodnostih.<br> Niste prejeli e-pošte? <a href="https://www.maistra.com/si/loyalty/activation/">Zahtevajte povezavo za aktivacijo.</a>',
      timeDisclaimer: 'Skupno število točk na podlagi vašega celotnega bivanja bo izračunano in dodano 48 ur po odjavi.',
      points: 'točk',
    },

    loyaltyBenefits: {
      title: 'Ugodnosti MaiStar Rewards Cluba',
      list: '<li>Do 20 % popust na rezervacije prek Maistrinega centra za rezervacije ali na strani maistra.com</li><li>Zbirajte točke in jih unovčite za ekskluzivne nagrade</li><li>Ekskluzivne ponudbe »samo za člane«</li><li>Vaš račun – vse na enem mestu</li>',
    },

    seeYouSoon: 'Se vidimo kmalu!',

    // TODO napake
    unitOrRateMissing: 'uporabnik ni izbral enote ali cene - na korak 1',
    personalDetailsMissing: 'uporabnik ni navedel osebnih podatkov - na korak 3',
    paymentDetailsMissing: 'uporabnik ni navedel podatkov o plačilu - na korak 4',
    propertyIdMissing: 'Vsaj propertyId mora biti naveden',
    tooManyGuests: 'Izbrana enota ni na voljo za želeno število ljudi. Poskusite znova z drugo enoto.',
    notEnoughAdults: `verjetno ni navedenih dovolj odraslih za nastanitev v izbranem številu sob in ni izpolnjen pogoj minAdults.
                      select Izberite drugačno število otrok in odraslih`,
    notAcceptingChildren: 'ta soba ni namenjena otrokom, izberite drugo sobo',
    missingPhobsId: 'verjetno manjka phobsId v CMS.<br>Poskusite z drugim objektom.',
    phobs: 'napake iz PHOBS: napake še niso definirane.'
  },
}

export { messagesSpecific }
