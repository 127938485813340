/* global Vue */

export default {
  name: 'Modal',
  template: `
  <div v-show="isOpen" class="modal" :style="containerOffset ? ('padding-left: '+containerOffset+'px') : ''" >
    <div class="modal__overlay" @click="$emit('closeModal')"></div>
    <div class="modal__container" ref="containerRef">
      <div class="modal__header" data-sl="mask">
        <div class="modal__back" v-if="showBackButton"><button @click="$emit('back')"><i class="icon-caret-left"></i></button></div>
        <div class="modal__button">
          <button @click="$emit('closeModal')">
            <span>{{ $t('cancel') }}</span>
            <i class="icon-close"></i>
          </button>
        </div>

        <h2 class="no-margin">
          <span v-if="title">{{ title }}</span>
        </h2>
      </div>

      <div class="modal__content">
        <slot />
      </div>

      <slot name="footer" />
    </div>
  </div>
  `,
  props: {
    isOpen: Boolean,
    title: String,
    overflowClass: String,
    containerOffset: Number,
    showBackButton: Boolean,
  },
  emits: ['closeModal', 'back'],
  setup (props) {
    const containerOffsetCandidate = Vue.toRef(props, 'containerOffset')
    const containerOffset = Vue.ref(0)
    const containerRef = Vue.ref(null)

    Vue.watch(() => props.isOpen, (isOpen) => {
      if (isOpen) {
        document.body.classList.add(props.overflowClass ? props.overflowClass : 'overflow-hidden')
      } else {
        document.body.classList.remove(props.overflowClass ? props.overflowClass : 'overflow-hidden')
      }

      Vue.nextTick(() => {
        handleResize()
      })
    })

    Vue.watch(containerOffsetCandidate, () => {
      Vue.nextTick(() => {
        handleResize()
      })
    })

    function handleResize () {
      if (!window.matchMedia('(min-width: 768px)').matches || !containerRef.value || !containerOffsetCandidate.value) {
        containerOffset.value = 0
        return
      }
      if (containerRef.value.offsetWidth + containerOffsetCandidate.value > window.innerWidth) {
        containerOffset.value = containerOffsetCandidate.value - (containerRef.value.offsetWidth + containerOffsetCandidate.value - window.innerWidth)
        return
      }
      containerOffset.value = containerOffsetCandidate.value
    }

    Vue.onMounted(async () => {
      window.addEventListener('resize', handleResize)
      handleResize()
    })

    Vue.onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize)
    })

    return {
      containerRef,
      containerOffset,
    }
  },
}
