/** global Vue */

import CldImg from '../components/CldImg'

export default {
  name: 'LoyaltyBenefits',
  components: { CldImg },
  props: {
    destination: String,
  },
  template: `
    <div class="see-you-soon section-padding section-margin-small-top">
      <CldImg :src="image" />

      <div class="container grid">
        <p class="h1 col-12 col-sm-11 offset-sm-1">{{ $t('seeYouSoon') }}</p>
      </div>
    </div>
  `,
  props: ['destination'],

  setup (props) {
    const image = Vue.computed(() => {
      if (props.destination === 'vrsar') return 'https://res.cloudinary.com/maistra/image/upload/v1630487044/Destinations/Vrsar/M15_012_00079_xptplo.jpg'
      if (props.destination === 'zagreb') return 'https://res.cloudinary.com/maistra/image/upload/v1657789915/Destinations/Zagreb/MCV%20Fotografije/DJI_0972_f5z7u8.jpg'
      if (props.destination === 'dubrovnik') return 'https://res.cloudinary.com/maistra/image/upload/v1687256927/Destinations/Dubrovnik_Arhiva_1_2_hkglak.jpg'

      return 'https://res.cloudinary.com/maistra/image/upload/v1695889714/Other/Rovinj_qnbhb0.jpg'
    })

    return {
      image,
    }
  }
}
