import { removeUnitByIndex, useCart } from '../../use-cart'
import { formatDateForMaistra } from '../../utils-common'

export default {
  name: 'ReservationSummary',
  template: `
<div class="reservation-summary" :class="{ ['reservation-summary--step'+stepNumber]: true , 'reservation-summary--inline': isInline, 'reservation-summary--open': isOpen, 'reservation-summary--disclaimer': buttonDisclaimer != ''}">
  <div class="reservation-summary__title">
    <h4>{{ $t('reservationSummary.title') }}</h4>
    <div role="button" @click="isOpenInline = !isOpenInline" class="reservation-summary__actions--toggle" v-if="isInline">
      <div v-if="priceQuote.grandTotal">
        <span>{{ isOpenInline ? $t('bookingModal.closeBtn') : $t('reservationSummary.total') }}</span>
        {{ isOpenInline ? '' : priceQuote.grandTotal?.display }}
      </div>
      <i :class="isOpenInline ? 'icon-caret-down' : 'icon-caret-up'"></i>
    </div>
    <span v-else @click="$emit('close')" role="button" >
      <i class="icon-close"></i>
    </span>
  </div>

  <div class="reservation-summary__content" v-show="!isInline || isOpenInline">
    <h4 class="hide-on-print">
      {{ property.title }}
      <span :class="'theme--' + property.brand" class="stars hide-on-print">
        <i v-for="n in property.stars" class="icon-star"></i>
      </span>
    </h4>

    <hr class="hide-on-print">

    <small>
      <span v-for="text in summaryTextParts">{{ text }}</span>
    </small>

    <hr class="hide-on-print">

    <div v-if="cart.rateId && hasPriceQuote">
      <div v-for="(unit, key) in priceQuote.units" class="reservation-summary__unit">
        <p class="reservation-summary__unit__number">
          <span>{{ $t('reservationSummary.unit') }} {{ key + 1 }}:</span>
          <a role="button" :title="$t('reservationSummary.removeUnit')" @click="() => removeUnitByIndex(key)" class="reservation-summary__unit__remove" v-if="canRemoveUnit"><i class="icon-trash"></i></a>
        </p>

        <p v-for="item in unit.priceItems" class="reservation-summary__price" :class="{ 'reservation-summary__price--base': item.type === 'BASE', 'reservation-summary__price--discount': item.type === 'REDUCTION'  }">
          <span>{{ item.name }}</span>
          <span>{{ item.type === 'REDUCTION' ? '-' : '' }}{{ item.price.display }}</span>
        </p>

        <p v-if="unitAvailability?.[key] === 1" class="reservation-summary__warning"><span class="icon-warning"></span>{{ $t('reservationSummary.lastAvailableUnit') }}</p>

        <p class="reservation-summary__price reservation-summary__price--unit-total">
          <span>{{ $t('reservationSummary.unitTotal') }}</span>
          <span>{{ unit.total.display }}</span>
        </p>

      </div>

      <div v-if="priceQuote.grandTotal && priceQuote.alternateTotal && cart.ratePlanIsMemberOnly.value" class="reservation-summary__alternate-total">
        <p class="reservation-summary__price">
          <span>{{ $t('reservationSummary.total').toUpperCase() }} {{ $t('reservationSummary.regularRate') }}</span>
          <span>{{ priceQuote.alternateTotal.display }}</span>
        </p>
        <p class="reservation-summary__price reservation-summary__price--discount">
          <span>{{ $t('reservationSummary.youSave') }}</span>
          <span>{{ priceQuote.youSave?.display }}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="reservation-summary__footer" :class="{ 'reservation-summary__footer--no-total': !(cart.rateId && hasPriceQuote) }" v-show="!isInline || isOpenInline">
    <p class="reservation-summary__price reservation-summary__price--total" v-if="cart.rateId && hasPriceQuote">
      <span>{{ $t('reservationSummary.total') }}</span>
      {{ cart.ratePlanIsMemberOnly.value ? $t('reservationSummary.memberRate') : $t('reservationSummary.regularRate')  }}
      <span>{{ priceQuote.grandTotal?.display }}</span>
    </p>
    <div class="reservation-summary__actions" v-if="!isInline">
      <div role="button" @click="isOpen ? $emit('close') : $emit('open')" class="reservation-summary__actions--toggle">
        <div v-if="cart.rateId && hasPriceQuote">
          <span>{{ isOpen ? $t('bookingModal.closeBtn') : $t('reservationSummary.total') }}</span>
          {{ isOpen ? '' : priceQuote.grandTotal?.display }}
        </div>
        <i :class="isOpen ? 'icon-caret-down' : 'icon-caret-up'"></i>
      </div>
      <div id="continue-button"></div>
    </div>
  </div>
</div>`,
  props: { priceQuote: Object, propertyAvailability: Object, property: Object, isOpen: Boolean, canRemoveUnit: Boolean, guestSummarySource: String, isInline: Boolean, class: String, stepNumber: Number },
  emits: ['open', 'close'],
  setup(props) {
    const { cart } = useCart()
    const { t } = VueI18n.useI18n()
    const { isOpen, guestSummarySource } = Vue.toRefs(props)
    const isOpenInline = Vue.ref(props.class === 'reservation-summary--step-4')
    const hasPriceQuote = Vue.computed(() => props.priceQuote?.rateId)
    const childrenSummary = Vue.computed(() => cart.childAges.value?.length > 0 ? `(${cart.childAges.value.join(', ')})` : '')
    const unitAvailability = Vue.computed(() => {
      const availabilityByUnit = props.propertyAvailability?.reduce((acc, unit) => {
        acc[unit.id] = unit.availableUnits
        return acc
      }, {})
      return props.priceQuote?.units.map((unit) => {
        if (availabilityByUnit[unit.unitId]) {
          availabilityByUnit[unit.unitId] -= 1
          return availabilityByUnit[unit.unitId] + 1
        }
        return 0
      })
    })

    const summaryTextParts = Vue.computed(() => {
      const [adults, children] = guestSummarySource.value === 'booking-bar'
        ? [cart.adults.value, cart.children.value.length]
        : [cart.guestSummary.value.adults, cart.guestSummary.value.children]

      return [
        `${formatDateForMaistra(cart.startDate.value)} - ${formatDateForMaistra(cart.endDate.value)}`,
        t('reservationSummary.nights', cart.nights.value),
        `${t('reservationSummary.adults', adults)}` + (children ? `, ${t('reservationSummary.children', children)} ${childrenSummary.value}` : ''),
        cart.rateId.value && hasPriceQuote.value ? cart.ratePlan.value.name : t('reservationSummary.selectRatePlan')
      ]
    })

    Vue.watch(isOpen, (show) => {
      if (show && !props.isInline && window.matchMedia('(max-width: 768px)').matches) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    })

    return {
      cart,
      isOpen,
      isOpenInline,
      hasPriceQuote,
      formatDateForMaistra,
      childrenSummary,
      summaryTextParts,
      unitAvailability,
      removeUnitByIndex,
    }
  }
}
