/* global Vue */

const mergeDeep = (...objects) => {
  const isObject = obj => obj && typeof obj === 'object'
  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key]
      const oVal = obj[key]
      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal)
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal)
      } else {
        prev[key] = oVal
      }
    })
    return prev
  }, {})
}
const isDebugEnabled = Vue.ref(JSON.parse(window.localStorage.getItem('isDebugEnabled')))

const isDateObject = d => Object.prototype.toString.call(d) === '[object Date]'

const normalizeTimezone = (d) => {
  if (isDateObject(d)) {
    return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
  }
  return d
}

const denormalizeTimezone = (d) => {
  if (isDateObject(d)) {
    return new Date(d.getTime() + d.getTimezoneOffset() * 60000)
  }
  return d
}

const formatNumber = (n, decimals = 0, maxDecimals = 10) => new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: decimals, maximumFractionDigits: maxDecimals }).format(n)

const compareDates = (date1, date2) => date1.toISOString().split('T')[0] === date2.toISOString().split('T')[0]

const formatDateForMaistra = (date) => {
  if (date instanceof Date) {
    return new Intl.DateTimeFormat('de-DE', { timeZone: 'Europe/Zagreb' }).format(date)
  } else {
    console.warn('date is not a Date', date)
  }
}

function displayDate (bookingParams) {
  if (!bookingParams.startDate) {
    return ''
  }
  let endDate = new Date(bookingParams.startDate)
  endDate = new Date(endDate.setDate(endDate.getDate() + bookingParams.nights))
  return `${formatDateForMaistra(bookingParams.startDate)} - ${formatDateForMaistra(endDate)}`
}

function displayGuests (bookingParams, t, hideChildren) {
  const adults = `${t('bookingModal.adults')}: ${bookingParams.adults}`
  if (hideChildren) {
    return adults
  }
  return `${adults}, ${t('bookingModal.noChildren')}: ${bookingParams.children.length}`
}

function parseChildrenFromUrl (rawAges) {
  if (!rawAges || rawAges.trim() === '') {
    return []
  }
  const childrenList = []
  rawAges.split(',').forEach(age => {
    childrenList.push({ age })
  })
  return childrenList
}

function localizeCalendar (locale) {
  const applyFormat = (date, format) => new Intl.DateTimeFormat(locale === 'si' ? 'sl' : locale, format).format(date)
  return {
    monthNames: [...Array(12).keys()].map((m) => applyFormat(new Date(2022, m), { month: 'long' })),
    dayNamesMin: [...Array(7).keys()].map((d) => applyFormat(new Date(2022, 7, d), { weekday: 'short' }).substring(0, 2)),
    firstDayOfWeek: 1,
  }
}

export {
  mergeDeep,
  normalizeTimezone,
  denormalizeTimezone,
  isDateObject,
  formatNumber,
  compareDates,
  formatDateForMaistra,
  displayDate,
  displayGuests,
  parseChildrenFromUrl,
  localizeCalendar,
  isDebugEnabled,
}
