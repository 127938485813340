/* global Vue */

import { MAX_ADULTS, MAX_CHILDREN, MAX_CHILD_AGE, MAX_GUESTS } from '../../enums/limits'

export default {
  name: 'BookingGuests',
  template: `
<div class="booking-guests">
  <div class="booking-guests__selector">
    <label for="adults">{{ $t('bookingModal.adults') }}</label>
  </div>
  <div class="booking-guests__selector">
    <div class="booking-guests__adult-input">
      <button @click="guests.adults -= 1" class="booking-guests__decrement" :disabled="guests.adults <= 0">-</button>
      <input
        disabled
        id="adults"
        v-model="guests.adults"
        placeholder="adults"
        type="number"
        :max="maxAdults"
        min="0">
        <button @click="guests.adults += 1" class="booking-guests__increment" :disabled="guests.adults >= maxAdults">+</button>
      </div>
  </div>

  <template v-if="maxChildAge > 0">
    <div class="booking-guests__separator"></div>

    <div class="booking-guests__selector">
      <label for="childrenCount">{{ $t('bookingModal.children') }}</label>
    </div>
    <div class="booking-guests__selector">
      <div class="booking-guests__children-input">
        <button @click="noOfChildren -= 1" class="booking-guests__decrement" :disabled="noOfChildren <= 0">-</button>
        <input
          disabled
          id="childrenCount"
          :value="guests.children.length"
          placeholder="number of children"
          type="number"
          :max="maxChildren"
          min="0">
        <button @click="noOfChildren += 1" class="booking-guests__increment" :disabled="noOfChildren >= maxChildren">+</button>
      </div>
    </div>
  </template>

  <template v-for="(child, index) in guests.children" :key="index">
    <div class="booking-guests__age">
      <label for="children">{{ $t('bookingModal.selectAge') }} {{ index + 1 }}</label>
    </div>
    <div class="booking-guests__age">
      <select id="children" v-model="child.age">
        <option v-for="n in maxChildAge" :value="n">{{ n }}</option>
      </select>
    </div>
  </template>

  <Teleport to="#modal-footer-teleport">
    <div class="modal__buttons">
      <button
        class="button"
        :disabled="guests.adults < 1"
        @click="$emit('updateGuests', guests)">
        {{ buttonLabel ? buttonLabel : $t('bookingModal.selectBtn') }}
      </button>
    </div>
  </Teleport>
</div>
  `,
  props: {
    children: Array,
    adults: Number,
    buttonLabel: String,
    property: Object,
  },
  emits: ['updateGuests'],

  setup (props, { emit }) {
    const { property } = Vue.toRefs(props)
    const maxChildAge = Vue.computed(() => property.value ? property.value.maxChildAge : MAX_CHILD_AGE)
    const guests = Vue.reactive({
      adults: props.adults,
      children: [...props.children],
    })

    const noOfChildren = Vue.ref([...props.children].length)

    Vue.watch(noOfChildren, (value) => {
      if (value < guests.children.length) {
        guests.children.pop()
      } else if (value > guests.children.length) {
        guests.children.push({ age: '1' })
      }
    })

    const maxAdults = Vue.computed(() => {
      const maxGuests = property.value ? property.value.maxGuests : MAX_GUESTS
      const maxAdults = property.value ? property.value.maxAdults : MAX_ADULTS

      if (maxGuests - guests.children.length <= maxAdults) {
        return maxGuests - guests.children.length
      }
      return maxAdults
    })

    const maxChildren = Vue.computed(() => {
      const maxGuests = property.value ? property.value.maxGuests : MAX_GUESTS
      const maxChildren = property.value ? property.value.maxChildren : MAX_CHILDREN

      if (maxGuests - guests.adults <= maxChildren) {
        return maxGuests - guests.adults
      }
      return maxChildren
    })

    Vue.watch(property.value, () => {
      if (guests.adults > maxAdults.value) {
        guests.adults = maxAdults.value
      }
      if (guests.children.length > maxChildren.value) {
        guests.children = guests.children.slice(0, maxChildren.value)
      }
    }, { immediate: true })

    Vue.watch(guests, (value) => {
      if (window.matchMedia('(min-width: 768px)').matches) {
        emit('updateGuests', value)
      }
    }, { deep: true })

    return {
      guests,
      noOfChildren,
      maxChildAge,
      maxAdults,
      maxChildren,
    }
  },
}
