/* global Vue, IS_BOOKING */

import BookingAccessCode from './BookingAccessCode'
import BookingGuests from './BookingGuests'
import BookingCalendar from './BookingCalendar'
import Modal from './Modal'
import { compareDates, displayDate, displayGuests } from '../../utils-common'
import PropertySelector from './PropertySelector'

export default {
  name: 'BookingModal',
  components: { BookingAccessCode, BookingGuests, BookingCalendar, PropertySelector, Modal },
  template: `
<Modal
  overflowClass="booking-modal-open"
  class="booking-modal"
  :isOpen="isOpen"
  :title="$t(modalTitle)"
  :containerOffset="containerOffset"
  :showBackButton="level !== 'inputs'"
  @closeModal="closeModal"
  @back="handleBack"
>
  <div v-if="level === 'inputs'" class="booking-menu__inputs grid">

    <div class="col-12 col-md-3" v-if="IS_BOOKING">
      <label for="property">
        {{ $t('bookingModal.property') }}
        <i class="icon-caret-down"></i>
      </label>
      <input
        id="property"
        placeholder="Select property"
        type="text"
        readonly
        @click="openPropertySelector"
        ref="propertyInput"
        :value="bookingParams.property.title">
    </div>

    <div class="col-12 col-md-3">
      <label for="dates">
        {{ $t('bookingModal.date') }}
        <i class="icon-caret-down"></i>
      </label>
      <input
        id="dates"
        placeholder="Select date range"
        type="text"
        @click="openCalendar"
        :value="displayDate(bookingParams)">
    </div>

    <div class="col-12 col-md-3">
      <label for="guests">
        {{ $t('bookingModal.guests') }}
        <i class="icon-caret-down"></i>
      </label>
      <input
        id="guests"
        placeholder="Select guests"
        type="text"
        @click="openGuests"
        :value="displayGuests(bookingParams, $t, property?.maxChildAge === 0)">
    </div>

    <div class="col-12 col-md-3" v-if="!isAccessCodeHidden">
      <BookingAccessCode :bookingParams="bookingParams" :accessCodeInfo="accessCodeInfo" />
    </div>

    <div v-if="bookingUrl" class="col-12 col-md-3">
      <a :href="bookingUrl" class="button button--outline" v-text="$t('bookNow')" />
    </div>

    <div v-else class="col-12 col-md-3 booking-menu__update">
      <button
        class="button"
        :class="bookingParamsChanged ? 'button--primary' : 'button--outline'"
        @click="updateBookingParams">
        {{ $t('bookingModal.updateBtn') }}
      </button>
    </div>
  </div>

  <PropertySelector
    v-if="level === 'property'"
    :property="bookingParams.property"
    @updateProperty="updateProperty" />

  <BookingCalendar
    v-if="level === 'calendar' && !availabilityCalendar.loading"
    :startDate="bookingParams.startDate"
    :nights="bookingParams.nights"
    :availabilityCalendar="availabilityCalendar"
    @dateSelected="updateCalendar" />

  <BookingGuests
    v-if="level === 'guests'"
    :adults="bookingParams.adults"
    :children="bookingParams.children"
    :property="property"
    @updateGuests="updateGuests" />

  <template #footer>
    <div id="modal-footer-teleport" class="modal__footer"></div>
  </template>
</Modal>
  `,
  props: {
    isOpen: Boolean,
    isAccessCodeHidden: Boolean,
    accessCodeInfo: Object,
    level: String,
    bookingUrl: String,
    bookingParams: Object,
    bookingParamsChanged: Boolean,
    availabilityCalendar: Object,
    property: Object,
    containerOffset: Number,
  },
  emits: ['updateBookingParams', 'error', 'closeModal', 'changeLevel', 'changeProperty'],
  setup (props, { emit }) {
    const modalTitle = Vue.ref('unitDemand.modifySearch')
    const bookingParams = props.bookingParams
    const availabilityCalendar = props.availabilityCalendar
    const valuesChanged = Vue.ref(false)

    Vue.watch(() => props.level, (level) => {
      switch (level) {
        case 'property':
          modalTitle.value = 'bookingModal.property'
          return
        case 'guests':
          modalTitle.value = 'bookingModal.guests'
          return
        case 'calendar':
          modalTitle.value = 'bookingModal.date'
          return
        default:
          modalTitle.value = 'unitDemand.modifySearch'
      }
    })

    function updateProperty (e) {
      emit('changeProperty', e)

      if (!window.matchMedia('(min-width: 768px)').matches) {
        emit('changeLevel', 'inputs')
      } else {
        emit('changeLevel', 'calendar')
      }
    }

    function updateCalendar (e) {
      if (!compareDates(bookingParams.startDate, e.startDate)) {
        bookingParams.startDate = e.startDate
      }
      bookingParams.nights = e.nights
      if (!window.matchMedia('(min-width: 768px)').matches) {
        emit('changeLevel', 'inputs')
      }
    }

    function updateGuests (e) {
      bookingParams.adults = e.adults

      const compareChildren = (a, b) => {
        if (a.length !== b.length) return true
        for (const i in a) {
          if (a[i].age !== b[i].age) return true
        }
        return false
      }

      if (compareChildren(bookingParams.children, e.children)) {
        bookingParams.children = e.children
      }

      if (!window.matchMedia('(min-width: 768px)').matches) {
        emit('changeLevel', 'inputs')
      }
    }

    function openPropertySelector () {
      emit('changeLevel', 'property')
    }

    function openCalendar () {
      emit('changeLevel', 'calendar')
    }

    function openGuests () {
      emit('changeLevel', 'guests')
    }

    function updateBookingParams () {
      emit('updateBookingParams', bookingParams)
    }

    function closeModal () {
      emit('changeLevel', 'inputs')
      emit('closeModal')
    }

    function handleBack () {
      emit('changeLevel', 'inputs')
    }

    return {
      bookingParams,
      availabilityCalendar,
      modalTitle,
      valuesChanged,
      displayDate,
      displayGuests,
      closeModal,
      handleBack,
      openPropertySelector,
      openCalendar,
      openGuests,
      updateGuests,
      updateCalendar,
      updateProperty,
      updateBookingParams,
      IS_BOOKING,
    }
  },
}
