/* global Vue, CLD_URL, CLD_TRANSFORM, DATA_LAYER, WWW_HOME_URL, PREFIX */

import UnitAvailable from '../components/UnitAvailable'
import UnitOnDemand from '../components/UnitOnDemand'
import LoyaltyBanner from '../../loyalty/components/LoyaltyBanner'
import { useCart } from '../../use-cart'
import { useState } from '../components/Notifications'
import { formatDateForMaistra, displayGuests } from '../../utils-common'

export default {
  name: 'Step1',
  components: { UnitAvailable, UnitOnDemand, LoyaltyBanner },
  template: `
<div>
  <section class="step-1">
    <div class="property-details">
      <div class="container">
        <div>
          <img :src="property.brandLogo" :alt="property.brand">
          <h4 class="h3">
            {{ property.title }}
            <span class="stars" :class="'theme--' + property.brand">
              <i v-for="n in property.stars" class="icon-star"></i>
            </span>
          </h4>
        </div>
        <hr>
        <a class="arrow-button" :href="property.link">
          <span>{{ $t('stepOne.discoverMore') }}</span>
          <i class="icon-arrow-right"></i>
        </a>
      </div>
    </div>

    <LoyaltyBanner v-if="!cart.isAuthenticated.value" />

    <p class="step-1__midtitle">
      {{ unitsAvailable.length }} {{ $t('stepOne.availableAccommodations') }}
       <b>{{ formatDateForMaistra(cart.startDate.value) }} - {{ formatDateForMaistra(cart.endDate.value) }} <i></i>
        {{ $t('reservationSummary.adults', cart.adults.value) }}<template v-if="property.maxChildAge !== 0">, {{ $t('reservationSummary.children', cart.children.value.length) }}</template>
       </b>
    </p>
    <UnitAvailable
      v-for="unit in unitsAvailable"
      @addToCart="e => $emit('addToCart', e)"
      :class="'theme--' + property.brand"
      :extras="extras"
      :contactURL="contactURL"
      :isMember="cart.isMember.value"
      :nights="cart.nights.value"
      :selectedUnits="cart.selectedUnits.value"
      :unit="unit"
      :maxChildAge="unit.maxChildAge ? unit.maxChildAge : property.maxChildAge"
      @modifyGuests="$emit('modifyGuests', $event)" />

    <p v-show="unitsOnRequest.length" class="step-1__midtitle step-1__midtitle--2">{{ $t('stepOne.unavailableAccommodations') }}</p>
    <UnitOnDemand
      v-for="unit in unitsOnRequest"
      :class="'theme--' + property.brand"
      :unit="unit"
      :contactURL="contactURL"
      @modifySearch="$emit('modifySearch', $event)" />
  </section>

  <teleport to="#continue-button">
    <span :class="{ 'disabled-button': !cart.canGo2Step2.value }"><button :disabled="!cart.canGo2Step2.value" @click="$router.push('/step2')"v-html="unallocatedGuests ? $t('stepOne.addUnitsForMoreGuests', unallocatedGuests) : $t('stepOne.continue')"></button></span>
  </teleport>
</div>
`,
  props: {
    property: Object,
    extras: String,
  },
  emits: ['addToCart', 'modifySearch', 'modifyGuests'],
  setup (props) {
    const { cart } = useCart()
    const { state } = useState()
    const { t } = VueI18n.useI18n()
    const { property } = Vue.toRefs(props)

    const unallocatedGuests = Vue.computed(() => {
      const { adults, children } = cart.guestsOverMaxOccupancy.value
      return Math.max(0, adults) + Math.max(0, children)
    })

    const units = Vue.computed(() => {
      if (!property.value?.units?.length) {
        return []
      }
      return property.value.units?.map((u) => {
        const unit = u
        if (typeof unit.image === 'string') {
          if (unit.image.includes(CLD_URL)) {
            unit.image = `${CLD_URL}w_912,h_608,${CLD_TRANSFORM}/${unit.image.replace(CLD_URL, '')}`
          }
        }
        return unit
      }).sort((a, b) => (a.weight ?? Number.MAX_VALUE) - (b.weight ?? Number.MAX_VALUE))
    })

    const unitsAvailable = Vue.computed(() => units.value.filter(u => u.isAvailable && u.isNumberOfChildrenAllowed(cart.children.value.length)) || [])
    const unitsOnRequest = Vue.computed(() => units.value.filter(u => u.isOnRequest && !u.hideIfUnavailable) || [])

    const contactURL = `${WWW_HOME_URL}${PREFIX}/${t('contactSlug')}/?property=${property.value.phobsId}`

    DATA_LAYER.bookingUnitList({ property })

    return {
      cart,
      state,
      units,
      contactURL,
      unitsAvailable,
      unitsOnRequest,
      unallocatedGuests,
      formatDateForMaistra,
      displayGuests,
    }
  }
}
